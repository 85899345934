import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import 'babel-polyfill';
import _ from 'lodash';

import Card from '@components/Card/Card.component';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';

import { fetchApiHooks, putApi } from '@services/api';

const styles = theme => ({
  avatar: {
    width: '50px',
    height: '50px',
    color: theme.palette.grey.main,
    backgroundColor: theme.palette.blue.light,
  },
  formControl: {
    width: '210px',
  },
  form: {
    '& .MuiGrid-item:not(:last-child)': {
      marginBottom: '0.6rem',
    },
  },
});

const Settings = ({ classes: st, pageData, notification }) => {
  const [user, setUser] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [loading, setLoading] = useState(false);

  fetchApiHooks(
    `users/${pageData?.auth?.uuid}`,
    {},
    res => setUser(res.data),
    [setRefresh],
    l => setLoading(l)
  );

  const updateUser = () => {
    putApi(`/users/${user.uuid}`, _.pick(user, ['name', 'wifiUser', 'wifiPass'])).then(async ({ status }) => {
      if (status === 200) notification('success', 'Sucess');
      else notification('error', 'An error occurred.');
    });
  };

  if (loading || _.isEmpty(user)) return <CircularProgress />;
  return (
    <Card title={user.name} avatar={<Avatar className={st.avatar}>FN</Avatar>}>
      <Grid container direction="column" className={st.form}>
        <Grid item>
          <TextField
            required
            error={_.isEmpty(user.name)}
            className={st.formControl}
            label="Name"
            defaultValue={user.name}
            onChange={({ target }) => setUser({ ...user, name: target.value })}
            helperText={_.isEmpty(user.name) && 'Name field cannot be empty.'}
          />
        </Grid>
        <Grid item>
          <TextField
            autoComplete="off"
            required
            error={_.isEmpty(user.wifiUser)}
            className={st.formControl}
            label="Wifi ID"
            defaultValue={user.wifiUser}
            onChange={({ target }) => setUser({ ...user, wifiUser: target.value })}
            helperText={_.isEmpty(user.wifiUser) && 'Wifi ID field cannot be empty.'}
          />
        </Grid>
        <Grid item>
          <FormControl className={st.formControl}>
            <InputLabel htmlFor="wifiPass">Password</InputLabel>
            <Input
              id="wifiPass"
              type={showPass ? 'text' : 'password'}
              value={user.wifiPass}
              onChange={({ target }) => setUser({ ...user, wifiPass: target.value })}
              autoComplete="off"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPass(!showPass)}
                    edge="end"
                  >
                    {showPass ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <FormHelperText id="wifiPass">
              {_.isEmpty(user.wifiPass) && 'Wifi password field cannot be empty.'}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" className={st.buttonForm} onClick={updateUser}>
            Update
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
};

Settings.propTypes = {
  pageData: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  notification: PropTypes.func.isRequired,
};

const mapStateToProps = ({ pageData }) => ({ pageData });
export default connect(mapStateToProps)(withStyles(styles)(Settings));
