import React, { useLayoutEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import moment from 'moment';

import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

import Card from '@components/Card/Card.component';
import TimelineIcon from '@material-ui/icons/Timeline';

am4core.useTheme(am4themes_animated);

const styles = () => ({
  chart: {
    height: '100%',
    width: '100%',
    minHeight: '100px',
    minWidth: '230px',
  },
});

const Line = ({ data, classes }) => {
  const chart = useRef(null);

  useLayoutEffect(() => {
    am4core.useTheme(am4themes_animated);
    // Create chart instance
    const x = am4core.create('chartLine', am4charts.XYChart);

    // Create axes
    const dateAxis = x.xAxes.push(new am4charts.DateAxis());

    // Create value axis
    const valueAxis = x.yAxes.push(new am4charts.ValueAxis());

    // Create series
    const lineSeries = x.series.push(new am4charts.LineSeries());
    lineSeries.dataFields.valueY = 'watts';
    lineSeries.dataFields.dateX = 'month';
    lineSeries.name = 'Sales';
    lineSeries.strokeWidth = 3;

    // Add simple bullet
    const circleBullet = lineSeries.bullets.push(new am4charts.CircleBullet());
    circleBullet.circle.stroke = am4core.color('#fff');
    circleBullet.circle.strokeWidth = 2;
    circleBullet.tooltipText = 'watts: [bold]{watts}[/]';

    const labelBullet = lineSeries.bullets.push(new am4charts.LabelBullet());
    labelBullet.label.text = '{watts}W';
    labelBullet.label.dy = -20;

    chart.current = x;

    return () => {
      x.dispose();
    };
  }, []);

  // When the value prop changes it will update the chart
  useLayoutEffect(() => {

    const sortData = _.sortBy(data, 'createdAt').reduce((arr, { reading, createdAt }) => {
      const month = moment(createdAt).format('MMM-YYYY');
      const i = _.findIndex(arr, ['month', month]);
      if (i < 0) arr.push({ firstReading: reading, lastReading: reading, month });
      else arr[i] = { ...arr[i], lastReading: reading };
      return arr;
    }, []);

    chart.current.data = sortData.map(d => ({ ...d, watts: _.round(d.lastReading - d.firstReading,2) }));
  // console.log('[DATA][2]:', chart.current.data);
}, [data]);

return (
  <Card title="Months" avatar={<TimelineIcon />}>
    <div className={classes.chart} id="chartLine" />
  </Card>
);
};

Line.propTypes = {
  data: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Line);
