import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import 'babel-polyfill';
import _ from 'lodash';

import { handleUpdateLanguage, handleUpdateAuth, handleUpdateError } from '@stores/page/page.actions';

import { login, checkAuth } from '@services/api';

import clsx from 'clsx';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import { blue } from '@material-ui/core/colors';

import loadingGif from '@resources/loading.gif';
import logoImg from '@resources/logo.png';
import logoBackground from '@resources/logo_background.jpg';

function Copyright() {
  return (
    <Typography align="center" color="textSecondary" variant="body2">
      {'Copyright ©  '}
      <Link color="inherit" href="https://material-ui.com/">
        Automac
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
  },
  loginBox: {
    backgroundImage: `url(${logoBackground})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor: theme.palette.type === 'dark' ? theme.palette.grey[900] : theme.palette.grey[50],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    width: '40%',
    minWidth: '350px',
    height: 'auto',
    marginBottom: '50px',
    [theme.breakpoints.down('xs')]: {
      width: '65%',
      marginBottom: 16,
      minWidth: '250px',
    },
  },
  lang: {
    marginTop: '20px',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    maxWidth: '400px',
    marginTop: theme.spacing(1),
    padding: '1rem',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  buttonProgress: {
    color: blue[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -8,
    marginLeft: -12,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  loading: {
    marginBottom: '2rem',
  },
}));

const Login = props => {
  const history = useNavigate();

  const { pageData, lang, dispatch: dp, notification } = props;

  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checkLoading, setCheckLoading] = useState(true);
  const [values, setValues] = React.useState({
    email: '',
    password: '',
    refreshToken: false,
    showPassword: false,
  });

  useEffect(() => {
    const checkAndRedirect = async () => {
      if (!_.isEmpty(pageData.auth) && pageData.auth?.localId) {
        const { status } = await checkAuth();
        if (status === 200) {
          setTimeout(() => history('/admin'), 2000);
          notification('success', lang('login.redirect'));
        } else {
          await dp(handleUpdateAuth({}));
          setErrorMsg(lang('login.sessionExpired'));
        }
      }
      setTimeout(() => setCheckLoading(false), 2000);
    };
    checkAndRedirect();
    if (pageData.error) {
      setErrorMsg(lang(`login.${pageData.error}`));
    }
  }, [dp, error, history, lang, notification, pageData.auth, pageData.error]);

  const auth = async e => {
    e.preventDefault();
    if (pageData.network) {
      await setLoading(true);
      const localId = Math.random().toString(16).substr(2, 8);
      const res = await login(values.email, values.password, values.refreshToken, localId);
      if (!res) {
        setErrorMsg(lang('login.serviceDown'));
        setError(true);
      }
      if (res.data.token) {
        await dp(handleUpdateAuth(res.data));
        history('/admin');
        dp(handleUpdateError(false));
      } else {
        let error = null;
        if (res.status === 401) {
          error = lang('login.notMach');
        } else if (res.status === 400) {
          error = lang('login.notMach'); // empty field
        }
        setError(true);
        setErrorMsg(error || lang('login.unknowError'));
        setLoading(false);
      }
    } else {
      setErrorMsg(lang('network.offline'));
      setError(true);
    }
  };

  const classes = useStyles();

  return (
    <Grid className={classes.root} component="main" container>
      <CssBaseline />
      <Grid className={classes.loginBox} container item justifyContent="center" square="true">
        <div className={classes.paper}>
          {checkLoading ? (
            <img alt="Loading..." className={classes.loading} src={loadingGif} />
          ) : (
            <>
              <img alt="Logo" className={classes.logo} src={logoImg} />
              <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                {errorMsg || lang('login.signIn')}
              </Typography>
              <form className={classes.form} onSubmit={e => auth(e)}>
                <TextField
                  autoComplete="email"
                  autoFocus
                  error={error}
                  fullWidth
                  id="email"
                  label={lang('login.email')}
                  margin="normal"
                  name="email"
                  onChange={e => setValues({ ...values, email: e.target.value })}
                  required
                  variant="outlined"
                />
                <FormControl className={clsx(classes.margin, classes.textField)} fullWidth variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">{lang('login.pass')}</InputLabel>
                  <OutlinedInput
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          edge="end"
                          onClick={() => setValues({ ...values, showPassword: !values.showPassword })}
                          onMouseDown={e => e.preventDefault()}
                        >
                          {values.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    error={error}
                    id="outlined-adornment-password"
                    labelWidth={70}
                    onChange={e => setValues({ ...values, password: e.target.value })}
                    type={values.showPassword ? 'text' : 'password'}
                    value={values.password}
                  />
                </FormControl>

                <Grid className={classes.lang} container>
                  <Grid item xs>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          onChange={e => setValues({ ...values, refreshToken: e.target.checked })}
                          value="remember"
                        />
                      }
                      label={lang('login.remember')}
                    />
                  </Grid>

                  <Grid item>
                    <TextField
                      helperText={lang('language.selectLanguage')}
                      id="outlined-select-currency"
                      label={lang('label.select')}
                      onChange={() => dp(handleUpdateLanguage(pageData.language === 'pt' ? 'en' : 'pt'))}
                      select
                      value={pageData.language}
                      variant="outlined"
                    >
                      {[
                        {
                          label: lang('language.english'),
                          value: 'en',
                        },
                        {
                          label: lang('language.portuguese'),
                          value: 'pt',
                        },
                      ].map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>

                <div className={classes.wrapper}>
                  <Button
                    className={classes.submit}
                    color="primary"
                    disabled={loading}
                    fullWidth
                    type="submit"
                    variant="contained"
                  >
                    {lang('login.signIn')}
                  </Button>
                  {loading && <CircularProgress className={classes.buttonProgress} size={24} />}
                </div>
                <Grid container>
                  <Grid item xs>
                    <Link href="#" variant="body2">
                      {lang('login.forgotPass')}
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link href="#" variant="body2">
                      {lang('login.signUp')}
                    </Link>
                  </Grid>
                </Grid>

                <Box mt={5}>
                  <Copyright />
                </Box>
              </form>
            </>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

Login.propTypes = {
  pageData: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  lang: PropTypes.func.isRequired,
  notification: PropTypes.func.isRequired,
};

const mapStateToProps = ({ pageData }) => ({ pageData });

export default connect(mapStateToProps)(Login);
