import { createTheme } from '@material-ui/core/styles';

// eslint-disable-next-line import/no-mutable-exports
let theme = createTheme({
  palette: {
    grey: {
      light: '#F0F0F0',
      main: '#6F7375',
      dark: '#676767',
    },
    blue: {
      light: '#F1FAFF',
      main: '#64B5F6',
      dark: '#49ACFB',
    },
    red: {
      light: '#FF8383',
      main: '#FF4747',
      dark: '#CF3C3C',
    },
    text: {
      secondary: '#6F7375',
    },
    primary: {
      light: '#F1FAFF',
      main: '#64B5F6',
      dark: '#49ACFB',
      contrastText: '#FFF',
    },
  },
  typography: {
    fontFamily: "'Roboto', 'Arial', sans-serif",
    h1: {
      fontFamily: "'Roboto', 'Arial', sans-serif",
      fontSize: '2rem', // 36.49px
      color: '#6F7375',
    },
    h2: {
      fontFamily: "'Roboto', 'Arial', sans-serif",
      fontSize: '1.8rem', // 32.44px
      fontWeight: 400,
      color: '#6F7375',
    },
    h3: {
      fontFamily: "'Roboto', 'Arial', sans-serif",
      fontSize: '1.2rem', // 22.78px
      color: '#6F7375',
    },
    h4: {
      fontFamily: "'Roboto', 'Arial', sans-serif",
      fontSize: '1.4rem', // 25.63px
      color: '#6F7375',
    },
    h5: {
      fontFamily: "'Roboto', 'Arial', sans-serif",
      fontSize: '1rem', // 20.25px
      fontWeight: 500,
      color: '#6F7375',
    },
    h6: {
      fontFamily: "'Roboto', 'Arial', sans-serif",
      fontSize: '0.8rem', // 16px
      fontWeight: 400,
      color: '#6F7375',
    },
    body: {
      fontSize: '0.8rem', // 16px
    },
    body2: {
      fontSize: '0.8rem', // 14.22px
      fontWeight: '300',
    },
    subtitle1: {
      fontSize: '1rem', // 20.25px
    },
    subtitle2: {
      fontSize: '0.5rem', // 12.64px
    },
    caption: {
      fontSize: '0.8rem', // 12.64px
      fontWeight: 300,
    },
  },
  shape: {
    borderRadius: 8,
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: '#3f4d67',
        boxShadow: '1px 0 20px 0 #3f4d67',
      },
    },
    MuiButton: {
      label: {
        textTransform: 'none',
      },
      contained: {
        boxShadow: 'none',
        '&:active': {
          boxShadow: 'none',
        },
      },
    },
    MuiTabs: {
      root: {
        marginLeft: theme.spacing(1),
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        backgroundColor: theme.palette.common.white,
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        margin: '0 16px',
        minWidth: 0,
        padding: 0,
        [theme.breakpoints.up('md')]: {
          padding: 0,
          minWidth: 0,
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: theme.spacing(1),
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: '#404854',
      },
    },
    MuiListItemText: {
      primary: {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    MuiListItemIcon: {
      root: {
        color: 'inherit',
        marginRight: 0,
        '& svg': {
          fontSize: 20,
        },
      },
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32,
      },
    },
  },
};

export { theme };
