import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import 'babel-polyfill';
import { networkStatusSync } from '@services/sync';

import Layout from '@layouts/Layout';
import Things from '@pages/Things';
import Energy from '@pages/Energy';
import Oil from '@pages/Oil';
import Heater from '@pages/Heater';

import { initDB } from 'react-indexed-db';
import snackbar from '@utils/snackbar';

// https://github.com/daneden/animate.css
import '@styles/libs/animate.css';

import Login from './Login';
import Settings from './Settings';

import { DBConfig } from '../database/db.config';

// INDEX DB
initDB(DBConfig);

export const lang = string => <FormattedMessage defaultMessage={string} id={string} />;

// https://iamhosseindhv.com/notistack/demos#variants
// Types: success, error, info, default, warning
export const notification = (variant, msg) => snackbar.notify(msg, { variant });

const Error = () => (
  <>
    <h2>Error 404 - Page Not Found.</h2>
    <button type="button" onClick={() => window.location.replace('/')}>
      BACK TO SAFETY
    </button>
  </>
);

const RouteAuth = ({ Component, authProps }) => {
  if (authProps.token) {
    return (
      <Layout lang={lang} notification={notification}>
        <Component lang={lang} notification={notification} />
      </Layout>
    );
  }
  return <Navigate to="/" />;
};

const App = ({ pageData, dispatch }) => {
  const { auth } = pageData;
  useEffect(() => networkStatusSync(dispatch), [dispatch]);
  return (
    <Router>
      <Routes>
        <Route
          exact
          path="/"
          element={<Login lang={lang} notification={notification} />}
        />
        <Route exact path="/index.html" element={<Navigate to="/admin/things" />} />
        <Route exact path="/admin" element={<Navigate to="/admin/things" />} />
        <Route exact path="/admin/settings" element={<RouteAuth Component={Settings} authProps={auth} />} />
        <Route exact path="/admin/things" element={<RouteAuth Component={Things} authProps={auth} />} />
        <Route exact path="/admin/things/energy/:uuid" element={<RouteAuth Component={Energy} authProps={auth} />} />
        <Route exact path="/admin/things/oil/:uuid" element={<RouteAuth Component={Oil} authProps={auth} />} />
        <Route exact path="/admin/things/heater/:uuid" element={<RouteAuth Component={Heater} authProps={auth} />} />
        <Route exact path="/admin/energy" element={<RouteAuth Component={Energy} authProps={auth} />} />
        <Route element={<Error />} />
      </Routes>
    </Router>
  );
};

App.propTypes = {
  dispatch: PropTypes.func.isRequired,
  pageData: PropTypes.object.isRequired,
};

const mapStateToProps = ({ pageData }) => ({ pageData });
export default connect(mapStateToProps)(App);
