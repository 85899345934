import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';

import CircularProgress from '@material-ui/core/CircularProgress';
import { fetchApi, postApi, putApi } from '@services/api';

const states = {
  NEW: { type: 'info', msg: "There's a new update available!" },
  ERROR: { type: 'error', msg: 'An error occured while trying to update. Please try again.' },
  SUCCESS: { type: 'success', msg: 'Device has been updated successfuly.' },
};

const styles = theme => ({});

const UpdateAlert = ({ active, data, classes: st }) => {
  const history = useNavigate();
  const [loading, setLoading] = useState(false);
  const [alertState, setAlertState] = useState(states[data.update]?.type || 'OK');
  const [thing, setThing] = useState({});

  useEffect(() => !_.isEmpty(data) && setThing(data), [data]);

  const done = async () => {
    await putApi(`/things/${thing.uuid}`, { update: 'OK' }).then(
      ({ status }) => status === 200 && setThing({ ...thing, update: 'OK' })
    );
  };

  const update = async () => {
    await setLoading(true);
    await fetchApi(`/firmwares/type/${thing.type}`).then(async ({ status: fStatus, data: firmware }) => {
      if (fStatus === 200)
        await postApi(`/things/${thing.uuid}/ota`, {
          version: firmware.version,
          filename: firmware.filename,
        }).then(async ({ status: otaStatus }) => {
          if (otaStatus === 200) {
            setTimeout(
              () =>
                fetchApi(`things/${thing.uuid}`)
                  .then(({ status: thingStatus, data: d }) => {
                    if (thingStatus === 200 && d.update === 'UPDATE') {
                      history('/admin/things');
                    } else {
                      setAlertState('error');
                      setThing({ ...thing, update: 'ERROR' });
                    }
                    setLoading(false);
                  })
                  .catch(error => {
                    setAlertState('error');
                    setLoading(false);
                  }),
              1500
            );
          } else {
            setAlertState('error');
            setLoading(false);
          }
        });
      else {
        setAlertState('error');
        setLoading(false);
      }
    });
  };

  if (!['NEW', 'ERROR', 'SUCCESS'].includes(thing.update) || !active) return false;
  return (
    <Grid item xs>
      <Alert
        variant="filled"
        severity={alertState}
        action={
          <Button
            className={st.button}
            startIcon={loading && <CircularProgress size={20} />}
            disabled={loading}
            color="inherit"
            size="small"
            onClick={() => (thing.update !== 'SUCCESS' ? update() : done())}
          >
            {thing.update !== 'SUCCESS' ? 'UPDATE' : 'DONE'}
          </Button>
        }
      >
        {states[thing.update].msg}
      </Alert>
    </Grid>
  );
};

UpdateAlert.propTypes = {
  active: PropTypes.bool,
  data: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(UpdateAlert);
