import { applyMiddleware, compose } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middleware = composeEnhancers(
  process.env.NODE_ENV !== 'production' ? applyMiddleware(thunk, logger) : applyMiddleware(thunk)
);

export default middleware;
