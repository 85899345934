import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import 'babel-polyfill';
import _ from 'lodash';
import moment from 'moment';
import mqtt from 'mqtt';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import DateFnsUtils from '@material-ui/pickers/adapter/date-fns';
import { DatePicker, LocalizationProvider } from '@material-ui/pickers';

import flashIcon from '@resources/icons/flash.svg';
import WifiOffIcon from '@material-ui/icons/WifiOff';
import WifiIcon from '@material-ui/icons/Wifi';
import EuroIcon from '@material-ui/icons/Euro';
import CircularProgress from '@material-ui/core/CircularProgress';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import RouterIcon from '@material-ui/icons/Router';

import ResetButton from '@components/ResetButton/ResetButton.component';
import UpdateAlert from '@components/UpdateAlert/UpdateAlert.component';
import DeleteButton from '@components/DeleteButton/DeleteButton.component';

import ColumnChart from '@components/charts/Energy.column.component';
import LineChart from '@components/charts/Energy.line.component';
import Card from '@components/Card/Card.component';
import { fetchApiHooks, postApi, putApi } from '@services/api';

const styles = theme => ({
  readingField: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: 16,
    },
  },
  realtimeFields: {
    color: theme.palette.grey.dark,
    [theme.breakpoints.down('xs')]: {
      marginTop: 16,
    },
  },
  energyRd: {
    padding: '30px 10px',
    minWidth: '200px',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    fontSize: '36px',
    fontWeight: 500,
    color: theme.palette.grey.dark,
    border: '1px solid',
    borderColor: theme.palette.grey.main,
    borderRadius: '10px',
    '& svg': {
      width: '35px',
      height: '35px',
      color: theme.palette.info.main,
    },
    '& p': {
      opacity: 0.5,
      fontWeight: 500,
      fontSize: '18px',
      marginLeft: '10px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '32px',
      width: '100%',
      '& p': {
        fontSize: '16px',
      },
      '& svg': {
        width: '30px',
        height: '30px',
      },
    },
  },
  pageLoading: {
    position: 'absolute',
    top: '10%',
    left: '50%',
  },
});

const Energy = ({ classes: st, notification, pageData }) => {
  const history = useNavigate();
  const { uuid } = useParams();
  const reading = useRef(null);
  const oldKwh = useRef(null);
  const newKwh = useRef(null);

  const [resp, setResp] = useState([]);
  const [previousDate, setPreviousDate] = useState(new Date());
  const [currentDate, setCurrentDate] = useState(new Date());
  const [sendLoading, setSendLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [thing, setThing] = useState({});
  const [first, setFirst] = useState({});
  const [loading, setLoading] = useState(false);
  const [client, setClient] = useState(null);
  const [rtReading, setRtReading] = useState({});
  const [rtConnected, setRtConnected] = useState(false);

  fetchApiHooks(
    `things/${uuid}`,
    {},
    res => setThing(res.data),
    [refresh],
    l => setLoading(l)
  );

  useEffect(() => setFirst(_.orderBy(thing?.readings, ['createdAt'], ['asc'])[0]), [thing]);

  useEffect(() => {
    if (client) {
      client.on('connect', () => {
        setRtConnected(true);
        client.subscribe(`/users/${pageData.auth.uuid}/things/${thing.uuid}/realtime/readings`);
      });
      client.on('error', err => {
        console.error('Connection error: ', err);
        client.end();
      });
      client.on('reconnect', () => setRtConnected(false));
      client.on('message', (topic, message) => {
        const body = JSON.parse(message.toString());
        setRtReading(body);
      });
    }
    return () => client && client.end();
  }, [client, pageData.auth.uuid, thing?.configs?.height, thing.uuid]);

  const connectMqtt = () =>
    setClient(
      mqtt.connect(process.env.MQTT_WS_URL, {
        clientId: `WEB/${Math.random().toString(16).substr(2, 8)}`,
        username: pageData.auth.uuid,
        password: pageData.auth.token,
        protocol: process.env.MQTT_WS_PROTOCOL,
        port: process.env.MQTT_WS_PORT,
        keepalive: 30,
        clean: true,
        reconnectPeriod: 3000,
        connectTimeout: 30 * 1000,
        rejectUnauthorized: false,
      })
    );

  const calc = () => {
    const oldKwhVal = oldKwh.current.childNodes[1].lastChild.value;
    const newKwhVal = newKwh.current.childNodes[1].lastChild.value;
    const prevDate = moment(previousDate);
    const currDate = moment(currentDate);
    const diffDays = currDate.diff(prevDate, 'days');

    const dif = newKwhVal - oldKwhVal;
    const unit = dif * 0.1739;
    const discount = (unit / 100) * 5.5; // 5.5% discount
    const subTotalUnit = unit - discount;
    const standingCharge = diffDays * 0.4037;
    const subTotal = subTotalUnit + standingCharge;
    const vat = (subTotal / 100) * 13.5;
    const res = subTotal + vat;
    const perDay = res / diffDays;

    setResp([dif, diffDays, _.ceil(res, 2), _.ceil(perDay, 2)]);
  };

  const sendReading = async () => {
    await setSendLoading(true);
    await putApi(`/things/${thing.uuid}`, { reading: _.toNumber(reading.current.value) }).then(
      async ({ status }) => {
        if (status === 200) {
          await postApi(`/things/${thing.uuid}/reset`, {}).then(({ status: stat }) => {
            if (stat === 200) notification('success', 'Sucess');
            else notification('error', 'An error occurred.');
          });
        }
      }
    );
    await setSendLoading(false);
    await setRefresh(!refresh);
  };

  if (loading || _.isEmpty(thing)) return <CircularProgress className={st.pageLoading} />;
  return (
    <Grid direction="column" container spacing={2}>
      <UpdateAlert data={thing} active={thing?.online} />
      <Grid item xs>
        <Card
          title={thing?.type}
          avatar={<img alt="Enegry" src={flashIcon} />}
          headerRight={
            <>
              <ResetButton
                user={thing.userUuid}
                thing={thing.uuid}
                active={thing.online}
                notification={notification}
              />
              <Tooltip arrow title={thing?.online ? 'Connected' : 'Disconnected'}>
                <IconButton color="inherit">{thing.online ? <WifiIcon /> : <WifiOffIcon />}</IconButton>
              </Tooltip>
            </>
          }
        >
          <Grid container spacing={3} alignItems="flex-start" justifyContent="space-between">
            <Grid item>
              <Typography align="left" color="textSecondary">
                <strong>First Collection: </strong>
                {`${first?.reading || 0} at ${moment(first?.createdAt).format('DD/MM/YYYY')}`}
              </Typography>
              <Typography align="left" color="textSecondary">
                <strong>Reading Time:</strong>
                {`${thing.interval / 60 / 60 / 1000}h`}
              </Typography>
              <Typography align="left" color="textSecondary">
                <strong>Current Reading: </strong>
                {`${thing?.reading ? `${thing?.reading} KW/H` : 'Waiting...'}`}
              </Typography>
              <Typography align="left" color="textSecondary">
                <strong>Last Updated:</strong>
                {moment(thing.updatedAt).fromNow()}
              </Typography>
            </Grid>

            <Grid item>
              <Typography variant="h3"> Set current reading </Typography>
              <TextField
                className={st.readingField}
                key={thing.reading}
                defaultValue={thing.reading}
                inputRef={reading}
                disabled={sendLoading || !thing.online}
              />
              <Button
                onClick={() => sendReading()}
                variant="contained"
                size="large"
                disabled={sendLoading || !thing.online}
              >
                {sendLoading ? 'SENDING...' : 'SUBMIT'}{' '}
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item xs>
        <Card
          title="Live Readings"
          avatar={<RouterIcon />}
          headerRight={
            <>
              <Tooltip arrow title="Realtime">
                <IconButton color="inherit" onClick={() => connectMqtt()} disabled={rtConnected}>
                  <PlayCircleOutlineIcon />
                </IconButton>
              </Tooltip>
              <Tooltip arrow title={rtConnected ? 'Connected' : 'Disconnected'}>
                {rtConnected ? <WifiIcon /> : <WifiOffIcon />}
              </Tooltip>
            </>
          }
        >
          <Grid container justifyContent="center" alignItems="center">
            <div className={st.energyRd}>
              <FlashOnIcon />
              {_.round(rtReading?.reading, 3) || 0}
              <Typography>KW/H</Typography>
            </div>
            <Grid item className={st.realtimeFields}>
              <Typography variant="body1">
                <strong>Watts:</strong> {_.round(rtReading?.watts, 2) || 0}
              </Typography>
              <Typography variant="body1">
                <strong>Amps:</strong> {_.round(rtReading?.amps, 2) || 0}
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item xs>
        <ColumnChart data={thing.readings} />
      </Grid>
      <Grid item xs>
        <LineChart data={thing.readings} />
      </Grid>
      <Grid item xs>
        <Card title="Bill Calculator" avatar={<EuroIcon />}>
          <Grid alignItems="center" container direction="column" justifyContent="center" spacing={3}>
            <Grid item xs>
              <LocalizationProvider dateAdapter={DateFnsUtils}>
                <DatePicker
                  label="Previous"
                  renderInput={props => <TextField {...props} />}
                  InputAdornmentProps={{ position: 'start' }}
                  value={previousDate}
                  onChange={date => setPreviousDate(date)}
                  inputFormat="dd/MM/yyyy"
                />
                <DatePicker
                  label="Current"
                  renderInput={props => <TextField {...props} />}
                  InputAdornmentProps={{ position: 'start' }}
                  value={currentDate}
                  onChange={date => setCurrentDate(date)}
                  inputFormat="dd/MM/yyyy"
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs>
              <TextField label="Previous KWH" ref={oldKwh} required variant="filled" />
              <TextField label="Current KWH" ref={newKwh} required variant="filled" />
            </Grid>

            <Grid item xs>
              <Button color="primary" onClick={calc} variant="contained">
                Check
              </Button>
            </Grid>

            <Grid item xs>
              {!_.isEmpty(resp) ? (
                <>
                  <Typography color="textSecondary" variant="h5">
                    Results
                  </Typography>
                  <Typography component="p" variant="body1">
                    Days: {resp[1]}
                  </Typography>
                  <Typography component="p" variant="body1">
                    Diff Unit: {resp[0]}
                  </Typography>
                  <Typography component="p" variant="body1">
                    Consumption per Day: <strong>€{resp[3]}</strong>
                  </Typography>
                  <Typography component="p" variant="body1">
                    Total: <strong>€{resp[2]}</strong>
                  </Typography>
                </>
              ) : null}
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item xs>
        <DeleteButton
          user={thing.userUuid}
          thing={thing.uuid}
          notification={notification}
          active={thing?.online}
        />
      </Grid>
    </Grid>
  );
};

Energy.propTypes = {
  pageData: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  notification: PropTypes.func.isRequired,
};

const mapStateToProps = ({ pageData }) => ({ pageData });
export default connect(mapStateToProps)(withStyles(styles)(Energy));
