export const fbConfig = {
  config: {
    apiKey: 'AIzaSyCCWmBIzQ5KY2C5ptzlUW1AeSICwIqcQ7U',
    authDomain: 'automac-prod.firebaseapp.com',
    databaseURL: 'https://automac-prod.firebaseio.com',
    projectId: 'automac-prod',
    storageBucket: 'automac-prod.appspot.com',
    messagingSenderId: '1058267334153',
    appId: '1:1058267334153:web:4fc85abd0648eb20d1dad8',
    measurementId: 'G-1EZEGQLFB2',
  },
  publicKey: 'BOVCFZoQlxXm3xQegZcLDy1FTkEL4NyQgcJL5aBVCVWvW5amVb8pxjjZO1ImLBesKbLkHWIF4vVl073X2mcWw4o',
};
