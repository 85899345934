import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import 'babel-polyfill';
import _ from 'lodash';

import styled from 'styled-components';

import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import Popover from '@material-ui/core/Popover';

import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import FormatAlignCenterIcon from '@material-ui/icons/FormatAlignCenter';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import notificationIcon from '@resources/icons/notification.svg';
import searchIcon from '@resources/icons/search.svg';
import AddDeviceBtn from '../components/AddDeviceButton/AddDeviceButton.component';
import ClockWidget from '../widgets/Clock/Clock.widget';

const styles = theme => ({
  header: {
    padding: theme.spacing(2, 4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  headerTitleContainer: {
    justifyContent: 'space-between',
    marginTop: 50,
    textTransform: 'capitalize',
    '& h1': {
      fontWeight: 500,
      marginBottom: '1rem',
    },
    '& p': {
      fontWeight: 500,
    },
    '& svg': {
      width: '35px',
      height: '35px',
      color: theme.palette.info.main,
      marginRight: '1rem',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 'calc(1rem + 3vw)',
      paddingLeft: '10px',
      '& p': {
        marginBottom: '1rem',
      },
      '& svg': {
        width: '30px',
        height: '30px',
        marginRight: '10px',
      },
    },
  },
  backButton: {
    borderRadius: 50,
    width: 130,
    height: 55,
    backgroundColor: theme.palette.blue.dark,
    color: 'white',
    fontSize: '1rem',
    '&:hover': {
      backgroundColor: theme.palette.blue.main,
    },
    '& svg': {
      width: '25px',
      height: '25px',
      color: 'white',
      margin: 0,
    },
    [theme.breakpoints.down('xs')]: {
      width: '25vw',
      height: '10vw',
      fontSize: 'calc(10px + 1vw)',
      whiteSpace: 'nowrap',
      minHeight: 40,
      maxHeight: 55,
      minWidth: 90,
      maxWidth: 130,
      '& svg': {
        width: '15px',
        height: '15px',
      },
    },
  },
  iconButtonAlert: {
    width: '55px',
    height: '55px',
    backgroundColor: '#FFF',
    boxShadow: '0px 3px 15px rgba(0, 0, 0, 0.1)',
    '& svg': {
      width: '20px',
      height: '20px',
      color: theme.palette.info.light,
    },
    '&:hover': {
      backgroundColor: theme.palette.blue.light,
    },
    [theme.breakpoints.down('xs')]: {
      width: '15vw',
      height: '15vw',
      fontSize: 'calc(10px + 1vw)',
      whiteSpace: 'nowrap',
      minHeight: 40,
      maxHeight: 55,
      minWidth: 40,
      maxWidth: 55,
      '& svg': {
        width: '15px',
        height: '15px',
      },
    },
  },
  avatarConatiner: {
    flex: 1,
    justifyContent: 'flex-end',
    flexWrap: 'nowrap',
    margin: 0,
  },
  avatar: {
    width: '50px',
    height: '50px',
    color: theme.palette.grey.main,
    backgroundColor: theme.palette.blue.light,
    [theme.breakpoints.down('xs')]: {
      width: '13vw',
      height: '13vw',
      minHeight: 38,
      minWidth: 38,
      maxHeight: 50,
      maxWidth: 50,
      fontSize: 'calc(14px + 1vw)',
    },
  },
  iconButtonAvatar: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 5,
    position: 'relative',
    width: '160px',
    height: '55px',
    borderRadius: 50,
    backgroundColor: '#FFF',
    boxShadow: '0px 3px 15px rgba(0, 0, 0, 0.1)',
    '& p': {
      width: '100%',
    },
    '&:hover': {
      backgroundColor: theme.palette.blue.light,
    },
    [theme.breakpoints.down('xs')]: {
      width: '45vw',
      height: '15vw',
      whiteSpace: 'nowrap',
      minHeight: 40,
      maxHeight: 55,
      minWidth: 100,
      maxWidth: 160,
      '& p': {
        fontSize: 'calc(10px + 1vw)',
      },
    },
  },
  searchButton: {
    width: '55px',
    height: '55px',
    borderRadius: '0px 70px 70px 70px',
    color: theme.palette.grey.main,
    backgroundColor: theme.palette.blue.dark,
    '&:hover': {
      backgroundColor: theme.palette.blue.main,
    },
  },
  searchBox: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    maxWidth: '300px',
    minWidth: '100px',
    height: '55px',
    borderRadius: 50,
    backgroundColor: '#FFF',
    boxShadow: '0px 3px 15px rgba(0, 0, 0, 0.1)',
    '& p': {
      width: '100%',
    },
    '& input': {
      outline: 'none',
      border: 'none',
      marginLeft: 20,
      width: '100%',
      minWidth: 50,
      '&::placeholder': {
        opacity: 0.3,
        fontSize: '1rem',
      },
    },
  },
  notifyContainer: {
    marginTop: 30,
    width: 300,
    height: 200,
    padding: 20,
    borderRadius: 30,
    boxShadow: '0px 3px 15px rgba(0, 0, 0, 0.1)',
    overflow: 'unset',
    '&::after': {
      content: '""',
      width: 0,
      height: 0,
      position: 'absolute',
      top: '-26px',
      margin: 'auto',
      left: 0,
      right: 0,
      borderLeft: '27px solid transparent',
      borderRight: '27px solid transparent',
      borderBottom: '27px solid white',
    },
  },
});

const IconButtonStyled = styled(IconButton)`
  opacity: ${({ open }) => (open ? 0 : 1)};
  color: ${({ theme }) => theme.palette.blue.dark};
  transition: all 500ms ease;
  transition-delay: ${({ open }) => (!open ? '200ms' : '0s')};
`;
function Header(props) {
  let location = useLocation();
  let history = useNavigate();
  const { classes: st, showMenu, pageData, open, dispatch, lang } = props;
  const [breadCrumbs, setBreadCrumbs] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  const searchRef = useRef();

  useEffect(() => {
    const path = location.pathname.split('/admin/')[1];
    const bc = path.split('/').reduce((arr, val) => (!val.match(/\d+/g) ? [...arr, val] : arr), []);
    setBreadCrumbs(['home', ...bc]);
  }, [location]);

  const handleClick = e => setAnchorEl(e.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const notifyOpen = Boolean(anchorEl);
  const id = notifyOpen ? 'simple-popover' : undefined;

  return (
    <Grid alignItems="center" container className={st.header}>
      <Grid alignItems="center" container spacing={2}>
        <Hidden smUp>
          <Grid item>
            <IconButtonStyled
              aria-label="open drawer"
              color="inherit"
              onClick={() => showMenu(true)}
              open={open}
            >
              <FormatAlignCenterIcon />
            </IconButtonStyled>
          </Grid>
        </Hidden>
        <Hidden xsDown>
          <Grid item style={{ flex: 1 }}>
            <div className={st.searchBox}>
              <input type="text" ref={searchRef} placeholder="Search..." />
              <IconButton className={st.searchButton} color="inherit">
                <img alt="Search" src={searchIcon} />
              </IconButton>
            </div>
          </Grid>
          <Grid item>
            <ClockWidget />
          </Grid>
        </Hidden>
        <Grid container className={st.avatarConatiner} spacing={2}>
          <Grid item>
            <Tooltip title={_.isEmpty(pageData.notifications) ? 'Alerts • No alerts' : ''}>
              <Badge
                color="secondary"
                overlap="circular"
                badgeContent={pageData.notifications.length}
                invisible={_.isEmpty(pageData.notifications)}
              >
                <IconButton
                  aria-describedby={id}
                  className={st.iconButtonAlert}
                  color="inherit"
                  onClick={!_.isEmpty(pageData.notifications) ? handleClick : () => null}
                >
                  <img alt="Notifications" src={notificationIcon} />
                </IconButton>
              </Badge>
            </Tooltip>
            <Popover
              id={id}
              open={notifyOpen}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              elevation={0}
              PaperProps={{ className: st.notifyContainer }}
            >
              <div className={st.notifyBox}>
                {pageData.notifications?.map(nt => (
                  <div
                    key={nt.uuid}
                    onClick={() => {
                      history(`/admin/things/${_.toLower(nt.type)}/${nt.uuid}`);
                      handleClose();
                    }}
                  >
                    {nt.msgType}
                  </div>
                ))}
              </div>
            </Popover>
          </Grid>
          <Grid item>
            <IconButton
              className={st.iconButtonAvatar}
              color="inherit"
              onClick={() => history('/admin/settings')}
            >
              <Typography color="textSecondary" variant="body2">
                Flavio Nunes
              </Typography>
              <Avatar className={st.avatar}>FN</Avatar>
            </IconButton>
          </Grid>
        </Grid>
      </Grid>

      <Grid alignItems="center" container className={st.headerTitleContainer}>
        <Grid item>
          <Typography color="textSecondary" variant="h1">
            <TrendingUpIcon /> {breadCrumbs[breadCrumbs.length - 1]}
          </Typography>
          <Typography color="textSecondary" variant="body2">
            {breadCrumbs.map((bc, i, a) => (i !== a.length - 1 ? `${bc} -> ` : bc))}
          </Typography>
        </Grid>
        <Grid item style={{ alignSelf: 'flex-end' }}>
          {breadCrumbs.length > 2 || breadCrumbs[1] === 'settings' ? (
            <Button
              className={st.backButton}
              variant="contained"
              color="primary"
              startIcon={<KeyboardBackspaceIcon />}
              onClick={() => history('/admin/things')}
            >
              Go back
            </Button>
          ) : (
            <AddDeviceBtn userUuid={pageData.auth?.uuid} />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  showMenu: PropTypes.func.isRequired,
  pageData: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  lang: PropTypes.func.isRequired,
};

const mapStateToProps = ({ pageData }) => ({ pageData });
export default connect(mapStateToProps)(withStyles(styles)(Header));
