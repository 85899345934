import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import 'babel-polyfill';
import _ from 'lodash';
import moment from 'moment';
import mqtt from 'mqtt';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

import LocalGasStationIcon from '@material-ui/icons/LocalGasStation';
import WifiOffIcon from '@material-ui/icons/WifiOff';
import WifiIcon from '@material-ui/icons/Wifi';
import CircularProgress from '@material-ui/core/CircularProgress';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import RouterIcon from '@material-ui/icons/Router';

import SolidGauge from '@components/charts/SolidGauge.component';

import ResetButton from '@components/ResetButton/ResetButton.component';
import UpdateAlert from '@components/UpdateAlert/UpdateAlert.component';
import DeleteButton from '@components/DeleteButton/DeleteButton.component';

import Card from '@components/Card/Card.component';
import { fetchApiHooks } from '@services/api';

const styles = theme => ({
  pageLoading: {
    position: 'absolute',
    top: '10%',
    left: '50%',
  },
});

const Oil = ({ classes: st, notification, pageData }) => {
  const history = useNavigate();
  const { uuid } = useParams();
  const reading = useRef(null);

  const [sendLoading, setSendLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [thing, setThing] = useState({});
  const [loading, setLoading] = useState(false);
  const [first, setFirst] = useState({});
  const [client, setClient] = useState(null);
  const [rtReading, setRtReading] = useState(0);
  const [rtConnected, setRtConnected] = useState(false);

  fetchApiHooks(
    `things/${uuid}`,
    {},
    res => setThing(res.data),
    [refresh],
    l => setLoading(l)
  );

  useEffect(() => setFirst(_.orderBy(thing?.readings, ['createdAt'], ['asc'])[0]), [thing]);

  useEffect(() => {
    if (client) {
      client.on('connect', () => {
        setRtConnected(true);
        client.subscribe(`/users/${pageData.auth.uuid}/things/${thing.uuid}/realtime/readings`);
      });
      client.on('error', err => {
        console.error('Connection error: ', err);
        client.end();
      });
      client.on('reconnect', () => setRtConnected(false));
      client.on('message', (topic, message) => {
        const body = JSON.parse(message.toString());
        let level = (body.reading / thing?.configs?.height) * 100 - 100;
        level = Math.floor(level * -1);
        setRtReading(level);
      });
    }
    return () => client && client.end();
  }, [client, pageData.auth.uuid, thing?.configs?.height, thing.uuid]);

  const connectMqtt = () =>
    setClient(
      mqtt.connect(process.env.MQTT_WS_URL, {
        clientId: `WEB/${Math.random().toString(16).substr(2, 8)}`,
        username: pageData.auth.uuid,
        password: pageData.auth.token,
        protocol: process.env.MQTT_WS_PROTOCOL,
        port: process.env.MQTT_WS_PORT,
        keepalive: 30,
        clean: true,
        reconnectPeriod: 3000,
        connectTimeout: 30 * 1000,
        rejectUnauthorized: false,
      })
    );

  if (loading || _.isEmpty(thing)) return <CircularProgress className={st.pageLoading} />;
  return (
    <Grid direction="column" container spacing={2}>
      <UpdateAlert data={thing} active={thing?.online} />
      <Grid item xs>
        <Card
          title={thing?.type}
          avatar={<LocalGasStationIcon />}
          headerRight={
            <>
              <ResetButton
                user={thing.userUuid}
                thing={thing.uuid}
                active={thing.online}
                notification={notification}
              />
              <Tooltip arrow title={thing?.online ? 'Connected' : 'Disconnected'}>
                <IconButton color="inherit">{thing.online ? <WifiIcon /> : <WifiOffIcon />}</IconButton>
              </Tooltip>
            </>
          }
        >
          <Grid item>
            <Typography align="left" color="textSecondary">
              <strong>First Collection: </strong>
              {`${first?.reading || 0} at ${moment(first?.createdAt).format('DD/MM/YYYY')}`}
            </Typography>
            <Typography align="left" color="textSecondary">
              <strong>Reading Time:</strong>
              {`${thing.interval / 60 / 60 / 1000}h`}
            </Typography>
            <Typography align="left" color="textSecondary">
              <strong>Current Reading: </strong>
              {`${thing?.reading ? `${thing?.reading} cm` : 'Waiting...'}`}
            </Typography>
            <Typography align="left" color="textSecondary">
              <strong>Last Updated:</strong>
              {moment(thing.updatedAt).fromNow()}
            </Typography>
          </Grid>
        </Card>
      </Grid>
      <Grid item xs>
        <Card
          title="Live Readings"
          avatar={<RouterIcon />}
          headerRight={
            <>
              <Tooltip arrow title="Realtime">
                <IconButton color="inherit" onClick={() => connectMqtt()} disabled={rtConnected}>
                  <PlayCircleOutlineIcon />
                </IconButton>
              </Tooltip>
              <Tooltip arrow title={rtConnected ? 'Connected' : 'Disconnected'}>
                {rtConnected ? <WifiIcon /> : <WifiOffIcon />}
              </Tooltip>
            </>
          }
        >
          <Grid container justifyContent="center" alignItems="center">
            <SolidGauge level={rtReading} />
          </Grid>
        </Card>
      </Grid>
      <Grid item xs>
        <DeleteButton
          user={thing.userUuid}
          thing={thing.uuid}
          notification={notification}
          active={thing?.online}
        />
      </Grid>
    </Grid>
  );
};

Oil.propTypes = {
  pageData: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  notification: PropTypes.func.isRequired,
};

const mapStateToProps = ({ pageData }) => ({ pageData });
export default connect(mapStateToProps)(withStyles(styles)(Oil));
