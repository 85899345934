export const DBConfig = {
  name: 'automac',
  version: 1,
  objectStoresMeta: [
    {
      store: 'things',
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: [
        { name: 'uuid', keypath: 'uuid', options: { unique: false } },
        { name: 'name', keypath: 'name', options: { unique: false } },
        { name: 'reading_time', keypath: 'reading_time', options: { unique: false } },
      ],
    },
    {
      store: 'sync',
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: [
        { name: 'reference_type', keypath: 'reference_type', options: { unique: false } },
        { name: 'reference_uuid', keypath: 'reference_uuid', options: { unique: false } },
        { name: 'action', keypath: 'action', options: { unique: false } },
        { name: 'data', keypath: 'data', options: { unique: false } },
      ],
    },
  ],
};
