import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import { handleUpdateRefresh } from '@stores/page/page.actions';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import Card from '@components/Card/Card.component';
import BluetoothIcon from '@material-ui/icons/Bluetooth';
import BuildIcon from '@material-ui/icons/Build';
import Typography from '@material-ui/core/Typography';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { postApi, fetchApi } from '@services/api';

const styles = theme => ({
  button: {
    borderRadius: 50,
    width: 130,
    height: 55,
    fontSize: '1rem',
    backgroundColor: theme.palette.blue.dark,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.blue.main,
    },
    [theme.breakpoints.down('xs')]: {
      width: '25vw',
      height: '10vw',
      fontSize: 'calc(10px + 1vw)',
      whiteSpace: 'nowrap',
      minHeight: 40,
      maxHeight: 55,
      minWidth: 90,
      maxWidth: 130,
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonForm: {
    margin: theme.spacing(1),
    height: 35,
  },
  formControl: {
    width: '100%',
  },
  form: {
    '& .MuiGrid-item:not(:last-child)': {
      marginBottom: '1rem',
    },
  },
  firstTime: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    minWidth: '300px',
    maxWidth: '300px',
    minHeight: '300px',
    maxHeight: '300px',
    overflow: 'hidden',
    margin: '8px',
    height: '100%',
    borderRadius: '30px',
    backgroundColor: 'white',
    boxShadow: '0px 3px 15px rgba(0, 0, 0, 0.1)',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '5rem',
    color: theme.palette.blue.main,
    cursor: 'pointer',
    '&::after': {
      content: '"."',
      opacity: 0.2,
      color: 'transparent',
      position: 'absolute',
      borderRadius: '20px',
      border: '2px dashed',
      borderColor: theme.palette.blue.main,
      height: '90%',
      width: '90%',
    },
  },
});
function onDisconnected(event) {
  console.log('> Bluetooth Device disconnected');
}
const AddDeviceButton = ({ firstTime, classes: st, userUuid }) => {
  const history = useNavigate();
  const refresh = useSelector(state => state.pageData.refresh);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [bleChar, setBleChar] = useState(false);
  const [form, setForm] = useState({});
  const [user, setUser] = useState({});

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    if (bleChar?.service?.device?.gatt?.connected) bleChar.service.device.gatt.disconnect();
  };

  const install = async () => {
    postApi(`/things`, { userUuid, ...form }).then(async ({ status, data: body }) => {
      if (status === 200) {
        const { uuid, password } = body;
        const encoder = new TextEncoder('utf-8');
        const env = window.location.hostname === 'localhost' ? 1 : 0;
        bleChar
          .writeValue(
            encoder.encode(`${user.wifiUser},${user.wifiPass},${user.uuid},${uuid},${password},${env}`)
          )
          .then(() => {
            bleChar.service.device.gatt.disconnect();
            dispatch(handleUpdateRefresh(!refresh));
            handleClose();
          });
        // await setBle(false);
      }
    });
  };

  const connectBLE = async () => {
    window.navigator.bluetooth
      .requestDevice({
        // acceptAllDevices: true,
        filters: [
          {
            services: [
              '4fafc201-1fb5-459e-8fcc-c5c9c331914b', // Automac-OIL
            ],
          },
        ],
      })
      .then(device => {
        console.log('BLE-OK');
        device.addEventListener('gattserverdisconnected', onDisconnected);
        return device.gatt.connect();
      })
      .then(async server => {
        const devices = await server.getPrimaryServices();
        return server.getPrimaryService(devices[0].uuid);
      })
      .then(async service => {
        const char = await service.getCharacteristics();
        return service.getCharacteristic(char[0].uuid);
      })
      .then(async characteristic => {
        await setBleChar(characteristic);
        const decoder = new TextDecoder('utf-8');
        characteristic.readValue().then(async data => {
          const split = decoder.decode(data).split('-');
          const type = split[0];
          const version = split[1];
          const name = { ENERGY: 'Energy Meter', OIL: 'Oil Meter', HEATER: 'Heater Switch' };
          await setForm({ name: name[type], type, interval: 1800000, reading: 0, configs: {}, version });
          await fetchApi(`/users/${userUuid}`).then(async ({ status, data: userData }) => {
            if (status === 200 && !_.isEmpty(userData.wifiUser)) await setUser(userData);
          });
          await handleOpen(true);
        });
      })
      .catch(error => {
        console.error(`Bluethoot Error: ${error}`);
      });
  };

  return (
    <>
      {firstTime ? (
        <div className={st.firstTime} onClick={() => connectBLE()}>
          +
        </div>
      ) : (
        <Button variant="contained" className={st.button} onClick={() => connectBLE()}>
          Add Device
        </Button>
      )}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={st.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div style={{ outline: 'none' }}>
            <Card title={form.type} avatar={<BluetoothIcon />}>
              <Grid container direction="column" className={st.form}>
                {_.isEmpty(user) ? (
                  <>
                    <Grid item>
                      <Typography variant="body1">
                        Please, update your wifi credentials before install this thing.
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => history('/admin/settings')}
                      >
                        Go to Settings
                      </Button>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item>
                      <TextField
                        required
                        error={_.isEmpty(form.name)}
                        className={st.formControl}
                        label="Name"
                        defaultValue={form.name}
                        onChange={({ target }) => setForm({ ...form, name: target.value })}
                        helperText={_.isEmpty(form.name) && 'Name field cannot be empty.'}
                      />
                    </Grid>
                    {form.type !== 'HEATER' && (
                      <Grid item>
                        <FormControl className={st.formControl}>
                          <InputLabel id="interval">Interval Reading Time</InputLabel>
                          <Select
                            labelId="interval"
                            id="interval"
                            value={form.interval / 1000 / 60}
                            onChange={({ target }) =>
                              setForm({ ...form, interval: target.value * 60 * 1000 })
                            }
                          >
                            <MenuItem value={30}>30 Minutes</MenuItem>
                            <MenuItem value={60}>1 Hour</MenuItem>
                            <MenuItem value={120}>2 Hours</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    )}
                    {form.type === 'ENERGY' && (
                      <Grid item>
                        <TextField
                          className={st.formControl}
                          label="Initial Reading"
                          defaultValue={form.reading}
                          onChange={({ target }) =>
                            setForm({ ...form, reading: parseFloat(target.value) || 0 })
                          }
                        />
                      </Grid>
                    )}
                    {form.type === 'OIL' && (
                      <Grid item>
                        <TextField
                          className={st.formControl}
                          label="Tank Height"
                          defaultValue={form.configs?.height}
                          onChange={({ target }) =>
                            setForm({
                              ...form,
                              configs: { ...form.configs, height: parseFloat(target.value) || 0 },
                            })
                          }
                          helperText="Must be in centimeters."
                        />
                      </Grid>
                    )}
                    <Grid container>
                      <Button
                        variant="contained"
                        color="default"
                        className={st.buttonForm}
                        onClick={handleClose}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        className={st.buttonForm}
                        startIcon={<BuildIcon />}
                        onClick={install}
                      >
                        Install
                      </Button>
                    </Grid>
                  </>
                )}
              </Grid>
            </Card>
          </div>
        </Fade>
      </Modal>
    </>
  );
};

AddDeviceButton.propTypes = {
  userUuid: PropTypes.string,
  firstTime: PropTypes.bool,
  classes: PropTypes.object.isRequired,
};

AddDeviceButton.defaultProps = {
  firstTime: false,
};
export default withStyles(styles)(AddDeviceButton);
