import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import 'babel-polyfill';
import _ from 'lodash';
import moment from 'moment';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

import heaterIcon from '@resources/icons/heater.svg';
import WifiOffIcon from '@material-ui/icons/WifiOff';
import WifiIcon from '@material-ui/icons/Wifi';
import CircularProgress from '@material-ui/core/CircularProgress';

import ResetButton from '@components/ResetButton/ResetButton.component';
import UpdateAlert from '@components/UpdateAlert/UpdateAlert.component';
import DeleteButton from '@components/DeleteButton/DeleteButton.component';

import Card from '@components/Card/Card.component';
import { fetchApiHooks } from '@services/api';

const styles = theme => ({
  button: {},
  pageLoading: {
    position: 'absolute',
    top: '10%',
    left: '50%',
  },
});

const Heater = ({ classes: st, notification, pageData }) => {
  const history = useNavigate();
  const { uuid } = useParams();
  const reading = useRef(null);

  const [sendLoading, setSendLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [thing, setThing] = useState({});
  const [loading, setLoading] = useState(false);
  const [first, setFirst] = useState({});

  fetchApiHooks(
    `things/${uuid}`,
    {},
    res => setThing(res.data),
    [refresh],
    l => setLoading(l)
  );

  useEffect(() => setFirst(_.orderBy(thing?.readings, ['createdAt'], ['asc'])[0]), [thing]);

  if (loading || _.isEmpty(thing)) return <CircularProgress className={st.pageLoading} />;
  return (
    <Grid direction="column" container spacing={2}>
      <UpdateAlert data={thing} active={thing?.online} />
      <Grid item xs>
        <Card
          title={thing?.type}
          avatar={<img alt="Heater" src={heaterIcon} />}
          headerRight={
            <>
              <ResetButton
                user={thing.userUuid}
                thing={thing.uuid}
                active={thing.online}
                notification={notification}
              />
              <Tooltip arrow title={thing?.online ? 'Connected' : 'Disconnected'}>
                <IconButton color="inherit">{thing.online ? <WifiIcon /> : <WifiOffIcon />}</IconButton>
              </Tooltip>
            </>
          }
        >
          <Grid item>
            <Typography align="left" color="textSecondary">
              <strong>First Collection: </strong>
              {`${first?.reading || 0} at ${moment(first?.createdAt).format('DD/MM/YYYY')}`}
            </Typography>
            <Typography align="left" color="textSecondary">
              <strong>Reading Time:</strong>
              {`${thing.interval / 60 / 60 / 1000}h`}
            </Typography>
            <Typography align="left" color="textSecondary">
              <strong>Current Reading: </strong>
              {`${thing.reading ? 'ON' : 'OFF'}`}
            </Typography>
            <Typography align="left" color="textSecondary">
              <strong>Last Updated:</strong>
              {moment(thing.updatedAt).fromNow()}
            </Typography>
          </Grid>
        </Card>
      </Grid>
      <Grid item xs>
        <DeleteButton
          user={thing.userUuid}
          thing={thing.uuid}
          notification={notification}
          active={thing?.online}
        />
      </Grid>
    </Grid>
  );
};

Heater.propTypes = {
  pageData: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  notification: PropTypes.func.isRequired,
};

const mapStateToProps = ({ pageData }) => ({ pageData });
export default connect(mapStateToProps)(withStyles(styles)(Heater));
