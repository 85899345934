import React from 'react';
import { createRoot } from 'react-dom/client';
import { legacy_createStore as createStore } from 'redux';
import { Provider } from 'react-redux';

import { ThemeProvider as ThemeProviderMUI } from '@material-ui/core/styles';
import { ThemeProvider as ThemeProviderSC } from 'styled-components';
import { theme } from '@styles/MUITheme';

import ConnectedIntlProvider from '@intl/ConnectedIntlProvider';
import { saveToLocalStorage, loadFromLocalStorage } from '@utils/localStorage';
import reducer from '@stores/root.reducer';
import middleware from '@stores/root.store';
import App from '@pages/App';

import { SnackbarProvider } from 'notistack';
import snackbar, { SnackbarUtilsConfigurator } from '@utils/snackbar';
import Button from '@material-ui/core/Button';

import { Workbox } from 'workbox-window';

// Note from 04/03/2020 - Flavio Nunes
// WEBPACK converts the sw.js into firebase-messaging-sw.js otherwise push notificiantion won't work
// tried use "useServiceWork()" from firebase but didn't work well. It break every window refresh
const action = () => (
  <Button color="primary" variant="contained" onClick={() => window.location.reload()}>
    Reload
  </Button>
);

if ('serviceWorker' in navigator && process.env.NODE_ENV === 'production') {
  const wb = new Workbox(`${window.location.origin}/firebase-messaging-sw.js`);
  wb.addEventListener('installed', event => {
    if (event.isUpdate) {
      console.log('update Available');
      snackbar.notify('An update is available!', {
        variant: 'info',
        autoHideDuration: 10000,
        action,
      });
    }
  });
  wb.register();
}


const persistedState = loadFromLocalStorage();
export const store = createStore(reducer, persistedState, middleware);
store.subscribe(() => saveToLocalStorage(store.getState()));
const container = document.getElementById('app');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(
  <Provider store={store}>
    <ConnectedIntlProvider>
      <ThemeProviderMUI theme={theme}>
        <ThemeProviderSC theme={theme}>
          <SnackbarProvider maxSnack={3} anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}>
            <SnackbarUtilsConfigurator />
            <App />
          </SnackbarProvider>
        </ThemeProviderSC>
      </ThemeProviderMUI>
    </ConnectedIntlProvider>
  </Provider>
);

if (module.hot) {
  console.log('hot');
  module.hot.accept('./pages/App.jsx', () => {
    // eslint-disable-next-line global-require
    const NextApp = require('./pages/App.jsx').default;
    root.render(
      <Provider store={store}>
        <ConnectedIntlProvider>
          <ThemeProviderMUI theme={theme}>
            <ThemeProviderSC theme={theme}>
              <SnackbarProvider maxSnack={3} anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}>
                <SnackbarUtilsConfigurator />
                <NextApp />
              </SnackbarProvider>
            </ThemeProviderSC>
          </ThemeProviderMUI>
        </ConnectedIntlProvider>
      </Provider>
    );
  });
}
