import { useEffect } from 'react';
import 'babel-polyfill';
import axios from 'axios';
import qs from 'query-string';
import moment from 'moment';
import _ from 'lodash';
import { notification, lang } from '@pages/App';
import { cacheAdapterEnhancer, throttleAdapterEnhancer } from 'axios-extensions';
import { handleUpdateAuth, handleUpdateError } from '@stores/page/page.actions';
import { loadFromLocalStorage } from '@utils/localStorage';
import { DBConfig } from '@database/db.config';
import { getAllIDB, addIDB } from '@database/db.services';
import { store } from '../index';

// const apiUrl = 'https://api.automac.app';
const apiUrl = process.env.API_URL;
const pageData = () => store.getState().pageData;

const axiosFetch = () => {
  const instance = axios.create({
    baseURL: apiUrl,
    headers: { Authorization: `Bearer ${pageData().auth?.token}` },
    adapter: throttleAdapterEnhancer(cacheAdapterEnhancer(axios.defaults.adapter, true)),
  });
  // Add a request interceptor
  instance.interceptors.request.use(
    async config => {
      // console.warn('sending..');
      const localStorage = loadFromLocalStorage();
      const auth = localStorage.pageData.auth || pageData().auth;
      // Do something before request is sent
      if (moment(auth.expiryTime).isBefore()) {
        console.warn('expired token...');
        if (auth.refreshToken) {
          // console.warn('getting new token...');
          const data_post = { refreshToken: auth.refreshToken, localId: auth.localId };
          const url = `${apiUrl}/auth/refresh`;
          const { status, data } = await axios.post(url, data_post).catch(e => e.response);

          if (status === 200) {
            await store.dispatch(
              handleUpdateAuth({
                ...auth,
                token: data.token,
                refreshToken: data.refreshToken,
                expiryTime: data.expiryTime,
              })
            );

            // CHECK AND UPDATE THE REFRESH TOKEN IN THE DATA POST FOR LOGOUT FUNCTION
            // if (config.data.refreshToken) {
            //   config.data = { ...config.data, refreshToken: data.refreshToken };
            // }

            config.headers.Authorization = `Bearer ${data.token}`;
            return config;
          }
          notification('warning', lang('login.sessionExpired'));
          await store.dispatch(handleUpdateError('sessionExpired'));
          await store.dispatch(handleUpdateAuth({}));
        } else {
          notification('warning', lang('login.sessionExpired'));
          await store.dispatch(handleUpdateError('sessionExpired'));
          await store.dispatch(handleUpdateAuth({}));
        }
      } else {
        return config;
      }
      // console.warn('BEFORE SENT:', config.headers.Authorization);
      // console.warn('BEFORE SENT DATA:', moment(pageData().auth.expiryTime).isBefore());
      // console.warn('BEFORE SENT - Expiry Date:', pageData().auth.expiryTime);
      // console.warn('BEFORE SENT - Now Date:', moment().format());
    },
    error =>
      // Do something with request error
      Promise.reject(error)
  );
  // Add a response interceptor
  instance.interceptors.response.use(
    resp => resp,
    async error => {
      if (error.response.status && (error.response.status === 401 || error.response.status === 403)) {
        notification('warning', lang('login.unauthorized'));
        await store.dispatch(handleUpdateAuth({}));
        return Promise.reject(error.response.data.error.message);
      }
      // Do something with response error
      return Promise.reject(error);
    }
  );
  return instance;
};

export function fetchApiHooks(endpoint, query, callback, deps = [], loading = () => null, trigger = false) {
  useEffect(() => {
    let didCancel = false;
    async function fetchData() {
      await loading(true);
      if (pageData().network) {
        // CHECK NETWORK STATUS BEFORE REQUEST
        await axiosFetch()
          .get(`${endpoint}?${qs.stringify(query)}`)
          .then(resp => {
            if (!didCancel) {
              resp.db = false;
              callback(resp);
              loading(false);
            }
          })
          .catch(error => {
            if (!didCancel) {
              callback(error.response);
              loading(false);
            }
          });
      } else {
        // Get the databases on INDEXDB and check if the given endpoint exist
        const databases = _.map(DBConfig.objectStoresMeta, 'store');
        if (_.includes(databases, endpoint)) {
          await getAllIDB(endpoint)
            .then(resp => {
              if (!didCancel) {
                const res = {};
                res.db = true;
                res.data = resp;
                callback(res);
                loading(false);
              }
            })
            .catch(error => {
              if (!didCancel) {
                callback(error.response);
                loading(false);
              }
            });
        } else {
          notification('error', lang('network.offline'));
          loading(false);
        }
      }
    }
    if (!trigger) {
      fetchData();
    }
    return () => {
      didCancel = true;
    };
  }, deps);
}

export async function fetchApi(endpoint, query) {
  return axiosFetch()
    .get(`${endpoint}?${qs.stringify(query)}`)
    .catch(e => e.response);
}

export async function postApi(endpoint, data) {
  // if (pageData().network) { // CHECK NETWORK STATUS BEFORE REQUEST
  return axiosFetch()
    .post(endpoint, data)
    .then(resp => resp)
    .catch(error => error);
  // } else {
  //   // Get the databases on INDEXDB and check if the given endpoint exist
  //   let databases = _.map(DBConfig.objectStoresMeta, 'store');
  //   if (_.includes(databases, endpoint)) {
  //     await addIDB({ reference_type: endpoint, reference_uuid: '', action: 'post', data }, 'sync');
  //     await addIDB(data, endpoint).then(resp => resp).catch(error => error);
  //   } else {
  //     notification('error', lang('network.offline'));
  //   }
  // }
}

export async function putApi(endpoint, data) {
  // if (pageData().network) { // CHECK NETWORK STATUS BEFORE REQUEST
  return axiosFetch()
    .put(endpoint, data)
    .then(resp => resp)
    .catch(error => error);
  // } else {
  //   // Get the databases on INDEXDB and check if the given endpoint exist
  //   let databases = _.map(DBConfig.objectStoresMeta, 'store');
  //   if (_.includes(databases, endpoint)) {
  //     await addIDB({ reference_type: endpoint, reference_uuid: '', action: 'post', data }, 'sync');
  //     await addIDB(data, endpoint).then(resp => resp).catch(error => error);
  //   } else {
  //     notification('error', lang('network.offline'));
  //   }
  // }
}

export async function delApi(endpoint) {
  // if (pageData().network) { // CHECK NETWORK STATUS BEFORE REQUEST
  return axiosFetch()
    .delete(endpoint)
    .then(resp => resp)
    .catch(error => error);
  // } else {
  //   // Get the databases on INDEXDB and check if the given endpoint exist
  //   let databases = _.map(DBConfig.objectStoresMeta, 'store');
  //   if (_.includes(databases, endpoint)) {
  //     await addIDB({ reference_type: endpoint, reference_uuid: '', action: 'post', data }, 'sync');
  //     await addIDB(data, endpoint).then(resp => resp).catch(error => error);
  //   } else {
  //     notification('error', lang('network.offline'));
  //   }
  // }
}

export async function login(email, password, refreshToken, localId) {
  const data = { email, password, refreshToken, localId };
  return axios.post(`${apiUrl}/auth/login`, data).catch(e => e.response);
}

export async function logout() {
  return axiosFetch()
    .get(`/auth/logout`)
    .catch(e => e.response);
}

export async function checkAuth() {
  return axiosFetch()
    .get(`/auth/check`)
    .catch(e => e.response);
}
