import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import 'babel-polyfill';
import _ from 'lodash';

import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import footerLinesLgIcon from '@resources/icons/footerLinesLg.svg';
import footerLinesSmIcon from '@resources/icons/footerLinesSm.svg';
import vectorBgUp from '@resources/icons/vectorBgUp.png';
import vectorBgDown from '@resources/icons/vectorBgDown.png';
import Navigator from './Navigator';
import Header from './Header';
import { theme } from '../styles/MUITheme.js';

const styles = {
  root: {
    display: 'flex',
    position: 'relative',
    minHeight: '100vh',
    maxWidth: '1100px',
  },
  app: {
    position: 'relative',
    left: '90px',
    width: 'calc(100% - 90px)',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    '@media (max-width:600px)': {
      left: 0,
      width: '100%',
    },
  },
  main: {
    position: 'relative',
    flex: 1,
    padding: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1),
    },
  },
  vector: {
    background: `url(${vectorBgDown}) 100px 0px no-repeat, url(${vectorBgUp}) right bottom no-repeat`,
    position: 'fixed',
    height: '100%',
    width: '100%',
    minHeight: '100vh',
    maxWidth: '1100px',
    [theme.breakpoints.down('sm')]: {
      background: `url(${vectorBgDown}) 100px 0px no-repeat`,
    },
    [theme.breakpoints.down('xs')]: {
      background: `url(${vectorBgDown}) top center no-repeat`,
    },
  },
  vectorBgTop: {
    position: 'absolute',
    left: 100,
    top: -30,
    opacity: 0.04,
    transform: 'rotate(180deg)',
    [theme.breakpoints.down('xs')]: {
      left: 0,
      right: 0,
      margin: 'auto',
    },
  },
  vectorBgBt: {
    position: 'absolute',
    bottom: 0,
    right: 20,
    opacity: 0.04,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  footer: {
    padding: theme.spacing(2),
    background: `url(${footerLinesLgIcon}) no-repeat no-repeat center`,
    height: '140px',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      background: `url(${footerLinesSmIcon}) no-repeat no-repeat center`,
    },
    '& p': {
      position: 'absolute',
      top: '55px',
      marginLeft: '100px',
      [theme.breakpoints.down('sm')]: {
        marginLeft: '30px',
        justifyContent: 'center',
      },
    },
  },
};

const Layout = props => {
  const history = useNavigate();
  const { classes, notification, lang, dispatch: dp, pageData, children } = props;
  const [open, setOpne] = useState(false);

  const showMenu = bool => {
    setOpne(bool);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Navigator open={open} showMenu={bool => showMenu(bool)} {...props} />
      <div className={classes.vector} />
      <div className={classes.app}>
        <Header showMenu={bool => showMenu(bool)} {..._.omit(props, ['classes'])} open={open} />
        <main className={classes.main}>{children}</main>
        <footer className={classes.footer}>
          <Typography align="center" color="textSecondary" variant="body2">
            {'Copyright © '}
            <Link color="inherit" href="https://automac.ie/">
              Automac
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
          </Typography>
        </footer>
      </div>
    </div>
  );
};

Layout.propTypes = {
  classes: PropTypes.object.isRequired,
  pageData: PropTypes.object.isRequired,
  children: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  lang: PropTypes.func.isRequired,
  notification: PropTypes.func.isRequired,
};

const mapStateToProps = ({ pageData }) => ({ pageData });
export default connect(mapStateToProps)(withStyles(styles)(Layout));
