/* eslint-disable */

export function saveToLocalStorage(state) {
  try {
    const serializedState = JSON.stringify({
      pageData: {
        auth: state.pageData.auth,
        notifications: [],
        search: [],
        loading: false,
        refresh: false,
        error: false,
        language: state.pageData.language,
        network: window.navigator.onLine,
      }
    });
    localStorage.setItem('state', serializedState);
  } catch (err) {
    console.error(err);
  }
}

export function loadFromLocalStorage() {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    console.error(err);
    return undefined;
  }
}