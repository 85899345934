import React, { useLayoutEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

am4core.useTheme(am4themes_animated);

const styles = () => ({
  chart: {
    height: '90px',
    width: '100%',
  },
});

const SolidGauge = ({ level, classes }) => {
  const [lvl, setLvl] = useState(0);
  setTimeout(() => setLvl(level), 1000);
  const chart = useRef(null);

  useLayoutEffect(() => {
    const x = am4core.create('chartSolidGauge', am4charts.GaugeChart);

    // Create axis
    const axis = x.xAxes.push(new am4charts.ValueAxis());
    axis.min = 0;
    axis.max = 100;
    axis.strictMinMax = true;
    axis.renderer.grid.template.strokeOpacity = 0;
    axis.renderer.labels.template.disabled = true;
    axis.renderer.ticks.template.disabled = true;
    axis.renderer.grid.template.disabled = true;
    // Set inner radius
    x.innerRadius = 70;

    axis.stroke = am4core.color('#fff'); // red
    axis.strokeWidth = 3; // 3px

    /**
     * Label
     */
    const label = x.radarContainer.createChild(am4core.Label);
    label.fill = am4core.color('#3f4d67');
    label.isMeasured = false;
    label.fontSize = 24;
    label.x = am4core.percent(50);
    label.y = am4core.percent(100);
    label.horizontalCenter = 'middle';
    label.verticalCenter = 'bottom';
    label.fillOpacity = 0.5;
    label.text = `${0}%`;

    // Add ranges
    const range = axis.axisRanges.create();
    range.value = 0;
    range.endValue = 30;
    range.axisFill.fillOpacity = 1;
    range.axisFill.fill = am4core.color('#ff3f40');
    range.axisFill.zIndex = -1;
    range.axisFill.cornerRadius = 5;

    const range2 = axis.axisRanges.create();
    range2.value = 30;
    range2.endValue = 70;
    range2.axisFill.fillOpacity = 1;
    range2.axisFill.fill = am4core.color('#dec066');
    range2.axisFill.zIndex = -1;
    range2.axisFill.cornerRadius = 5;

    const range3 = axis.axisRanges.create();
    range3.value = 70;
    range3.endValue = 100;
    range3.axisFill.fillOpacity = 1;
    range3.axisFill.fill = am4core.color('#8bd870');
    range3.axisFill.zIndex = -1;
    range3.axisFill.cornerRadius = 5;

    // Add hand
    const hand = x.hands.push(new am4charts.ClockHand());
    hand.value = 0;
    // hand.pin.disabled = false;
    hand.fill = am4core.color('#009be5');
    hand.strokeOpacity = 0;
    hand.innerRadius = 0;
    hand.radius = am4core.percent(90);
    hand.startWidth = 5;

    chart.current = x;
    chart.current.label = label;
    chart.current.hand = hand;

    return () => {
      x.dispose();
    };
  }, []);

  // When the value prop changes it will update the chart
  useLayoutEffect(() => {
    for (let i = 0; i <= lvl; i += 1) {
      setTimeout(() => {
        chart.current.label.text = `${i}%`;
      }, i * 20);
    }
    chart.current.hand.animate({ property: 'value', to: lvl }, lvl * 20, am4core.ease.cubicInOut);
  }, [lvl]);

  return <div className={classes.chart} id="chartSolidGauge" />;
};

SolidGauge.propTypes = {
  level: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SolidGauge);
