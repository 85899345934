import React, { useLayoutEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import moment from 'moment';

import EqualizerIcon from '@material-ui/icons/Equalizer';
import Card from '@components/Card/Card.component';

import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

am4core.useTheme(am4themes_animated);

const styles = () => ({
  chart: {
    height: '100%',
    width: '100%',
    maxHeight: '400px',
    minHeight: '300px',
    minWidth: '230px',
  },
});

const Column = ({ data, classes }) => {
  const chart = useRef(null);

  useLayoutEffect(() => {
    // Themes begin
    am4core.useTheme(am4themes_animated);
    // Themes end

    // Create chart instance
    const x = am4core.create('chartColumn', am4charts.XYChart);
    x.dateFormatter.dateFormat = 'HH:mm';

    const dateAxis = x.xAxes.push(new am4charts.DateAxis());
    dateAxis.groupData = true;
    dateAxis.groupCount = 20;
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.title.text = 'Date';
    dateAxis.renderer.minGridDistance = 20;

    const valueAxis = x.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = 'Watts';

    // Create series
    const series = x.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = 'watts';
    series.groupFields.valueY = 'sum';
    series.dataFields.dateX = 'date';
    series.name = 'watts';
    series.columns.template.tooltipText = '{dateX}: [bold]{valueY} watts[/]';
    series.strokeWidth = 2;
    series.columns.template.strokeWidth = 0;

    // Add cursor
    x.cursor = new am4charts.XYCursor();

    // Add simple vertical scrollbar
    x.scrollbarY = new am4core.Scrollbar();
    x.scrollbarX = new am4core.Scrollbar();

    chart.current = x;

    return () => {
      x.dispose();
    };
  }, []);

  // When the value prop changes it will update the chart
  useLayoutEffect(() => {
    if (!_.isEmpty(data)) {
      chart.current.data = _.sortBy(data, 'createdAt').reduce((arr, { reading, createdAt }) => {
        // only one day
        if (moment(createdAt).isSame(moment(), 'day')) {
          const date = new Date(createdAt);
          if (_.isEmpty(arr)) arr.push({ reading, date, watts: 0 });
          else
            arr.push({
              reading,
              date,
              watts: _.round((reading - arr[arr.length - 1].reading) * 1000, 2),
            });
        }
        return arr;
      }, []);
      // console.log('DATA:', chart.current.data);
    }
  }, [data]);

  return (
    <Card title="Today" avatar={<EqualizerIcon />}>
      <div className={classes.chart} id="chartColumn" />
    </Card>
  );
};

Column.propTypes = {
  data: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Column);
