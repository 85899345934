import React, { useState, useEffect, useRef } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip';

import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import { postApi } from '@services/api';

const styles = theme => ({});

const ResetButton = ({ active, user, thing, notification, classes: st }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const reset = async () =>
    postApi(`/things/${thing}/reset`, {}).then(({ status }) => {
      if (status === 200) notification('success', 'Sucess');
      else notification('error', 'An error occurred.');
      handleClose();
    });

  return (
    <>
      <Tooltip arrow title="Reset">
        <>
          <IconButton color="inherit" onClick={handleClickOpen} disabled={!active}>
            <RotateLeftIcon />
          </IconButton>
        </>
      </Tooltip>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">This device will be reseted.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            No
          </Button>
          <Button onClick={() => reset()} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

ResetButton.propTypes = {
  active: PropTypes.bool,
  thing: PropTypes.string.isRequired,
  user: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  notification: PropTypes.func.isRequired,
};

ResetButton.defaultProps = {
  active: true,
};
export default withStyles(styles)(ResetButton);
