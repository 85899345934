import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import moment from 'moment';

import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import timeIcon from '@resources/icons/time.svg';

const styles = theme => ({
  clock: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    padding: 5,
    position: 'relative',
    width: '185px',
    height: '55px',
    borderRadius: 50,
    backgroundColor: '#FFF',
    boxShadow: '0px 3px 15px rgba(0, 0, 0, 0.1)',
    '& h1': {
      width: '100%',
      fontWeight: 400,
    },
    '&:hover': {
      backgroundColor: theme.palette.blue.light,
    },
  },
  avatar: {
    width: '50px',
    height: '50px',
    backgroundColor: '#f0faff',
    '& svg': {
      color: theme.palette.info.light,
    },
  },
});

const Clock = ({ active, classes: st }) => {
  const [time, setTime] = useState(moment().format('HH:mm'));
  useEffect(() => {
    setInterval(() => setTime(moment().format('HH:mm')), 1000);
  }, []);
  return (
    <div className={st.clock} onClick={() => null}>
      <Avatar className={st.avatar}>
        <img alt="time" src={timeIcon} />
      </Avatar>
      <Typography color="textSecondary" variant="h1">
        {time}
      </Typography>
    </div>
  );
};

Clock.propTypes = {
  active: PropTypes.bool,
  classes: PropTypes.object.isRequired,
};

Clock.defaultProps = {
  active: true,
};
export default withStyles(styles)(Clock);
