import { getAllIDB, delIDB } from '@database/db.services';
import { postApi } from '@services/api';
import { notification, lang } from '@pages/App';
import { handleUpdateNetwork } from '@stores/page/page.actions';
import _ from 'lodash';

export const netSync = async online => {
  if (online) {
    await getAllIDB('sync').then(data => {
      if (!_.isEmpty(data)) {
        notification('info', lang('network.synchronizing'));
        data.map(async item => {
          if (item.action === 'post') {
            await postApi(item.reference_type, item.data).then(resp =>
              resp.status === 201 ? delIDB(item.id, 'sync') : notification('error', lang('network.syncFail'))
            );

            notification('success', lang('network.syncSuccess'));
          } else {
            notification('error', lang('network.syncFail'));
          }
        });
      }
    });
  }
  notification(online ? 'success' : 'warning', online ? lang('network.online') : lang('network.offline'));
};
export const networkStatusSync = dispatch => {
  window.addEventListener('online', () => {
    netSync(true);
    dispatch(handleUpdateNetwork(true));
  });
  window.addEventListener('offline', () => {
    netSync(false);
    dispatch(handleUpdateNetwork(false));
  });
};
