export const UPDATE_AUTH = 'UPDATE_AUTH';
export const UPDATE_NOTIFICATIONS = 'UPDATE_NOTIFICATIONS';
export const UPDATE_SEARCH = 'UPDATE_SEARCH';
export const UPDATE_LOADING = 'UPDATE_LOADING';
export const UPDATE_REFRESH = 'UPDATE_REFRESH';
export const UPDATE_ERROR = 'UPDATE_ERROR';
export const UPDATE_LANGUAGE = 'UPDATE_LANGUAGE';
export const UPDATE_NETWORK = 'UPDATE_NETWORK';

const auth = object => ({ type: UPDATE_AUTH, object });
const notifications = array => ({ type: UPDATE_NOTIFICATIONS, array });
const search = array => ({ type: UPDATE_SEARCH, array });
const loading = boolean => ({ type: UPDATE_LOADING, boolean });
const refresh = boolean => ({ type: UPDATE_REFRESH, boolean });
const error = string => ({ type: UPDATE_ERROR, string });
const language = string => ({ type: UPDATE_LANGUAGE, string });
const network = boolean => ({ type: UPDATE_NETWORK, boolean });

export const handleUpdateAuth = object => dp => dp(auth(object));
export const handleUpdateNotifications = array => dp => dp(notifications(array));
export const handleUpdateSearch = array => dp => dp(search(array));
export const handleUpdateLoading = boolean => dp => dp(loading(boolean));
export const handleUpdateRefresh = boolean => dp => dp(refresh(boolean));
export const handleUpdateError = string => dp => dp(error(string));
export const handleUpdateLanguage = string => dp => dp(language(string));
export const handleUpdateNetwork = boolean => dp => dp(network(boolean));
