import 'babel-polyfill';
import { logout as logOut } from '@services/api';
import {
  handleUpdateAuth,
  handleUpdateLanguage,
  handleUpdateSearch,
  handleUpdateNotifications,
} from '../stores/page/page.actions';

export default async function logout(props) {
  await logOut();
  await props.dispatch(handleUpdateLanguage('en'));
  await props.dispatch(handleUpdateAuth({}));
  await props.dispatch(handleUpdateNotifications([]));
  await props.dispatch(handleUpdateSearch([]));
  props.history('/');
}
