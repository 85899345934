import {
  UPDATE_AUTH,
  UPDATE_SEARCH,
  UPDATE_LOADING,
  UPDATE_REFRESH,
  UPDATE_NOTIFICATIONS,
  UPDATE_ERROR,
  UPDATE_LANGUAGE,
  UPDATE_NETWORK,
} from './page.actions';

const INITIAL_STATE = {
  auth: {},
  notifications: [],
  search: [],
  loading: false,
  refresh: false,
  error: false,
  language: 'en',
  network: navigator.onLine,
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_AUTH:
      return { ...state, auth: action.object };
    case UPDATE_NOTIFICATIONS:
      return { ...state, notifications: action.array };
    case UPDATE_SEARCH:
      return { ...state, search: action.array };
    case UPDATE_LOADING:
      return { ...state, loading: action.boolean };
    case UPDATE_REFRESH:
      return { ...state, refresh: action.boolean };
    case UPDATE_ERROR:
      return { ...state, error: action.string };
    case UPDATE_LANGUAGE:
      return { ...state, language: action.string };
    case UPDATE_NETWORK:
      return { ...state, network: action.boolean };
    default:
      return state;
  }
};

export default userReducer;
