/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';

import { darken } from '@material-ui/core/styles';
import styled from 'styled-components';

import HomeIcon from '@material-ui/icons/Home';
import ScatterPlotIcon from '@material-ui/icons/ScatterPlot';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import SettingsIcon from '@material-ui/icons/Settings';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import CloseIcon from '@material-ui/icons/Close';
import logout from '@utils/logout';
import logoImg from '@resources/icons/icon-384x384.png';

const menu = [
  { key: 'things', name: 'Things', icon: <ScatterPlotIcon />, textWidth: 40.39, route: '/admin/things' },
  // { key: 'dashboard', name: 'Dashboard', icon: <EqualizerIcon />, textWidth: 83.77, route: '/admin/energy' },
  { key: 'settings', name: 'Settings', icon: <SettingsIcon />, textWidth: 66.69, route: '/admin/settings' },
  { name: 'Exit', icon: <ExitToAppIcon />, textWidth: 29.83, route: false },
];

const MenuItem = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: ${({ theme, active }) => (active ? '#eaf8ff' : 'transparent')};
  height: ${({ textwidth }) => (textwidth > 70 ? 220 : 200)}px;
  width: 100%;
  transition: all 800ms ease;
  cursor: ${({ active }) => (active ? 'unset' : 'pointer')};
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  &:last-child {
    position: absolute;
    bottom: 50px;
  }
  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 0;
    height: 90px;
    background-color: white;
    transition: all 800ms ease;
  }
  &::before {
    top: -37px;
    border-radius: ${({ active }) => (active ? '0 0 65% 0' : '50%')};
    width: ${({ active }) => (active ? '100%' : '85%')};
    opacity: ${({ active }) => (active ? '1' : '0')};
  }
  &::after {
    top: ${({ textwidth }) => (textwidth > 70 ? 166 : 146)}px;
    border-radius: ${({ active }) => (active ? '0 65% 0 0' : '50%')};
    width: ${({ active }) => (active ? '100%' : '85%')};
  }
  & p {
    position: absolute;
    left: ${({ active }) => (active ? '50px' : '40px')};
    opacity: ${({ active }) => (active ? 1 : 0)};
    font-weight: 500;
    top: ${({ textwidth }) => (textwidth > 70 ? 110 + textwidth / 2 - 14 : 100 + textwidth / 2 - 14)}px;
    color: ${({ theme }) => theme.palette.blue.dark};
    text-transform: uppercase;
    transform-origin: 0 0;
    transform: rotate(-90deg);
    transition: all 500ms ease;
    transition-delay: ${({ active }) => (active ? '400ms' : 'unset')};
  }
  & div {
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    height: ${({ textwidth }) => (textwidth > 70 ? 120 : 100)}px;
    background-color: ${({ active }) => (active ? 'white' : 'white')};
    &::after {
      transition: all 800ms ease;
      content: '';
      position: absolute;
      top: 0px;
      left: ${({ active }) => (active ? '16px' : '85px')};
      width: ${({ active }) => (active ? '83%' : '0px')};
      height: ${({ textwidth }) => (textwidth > 70 ? 120 : 100)}px;
      background-color: ${({ theme, active }) => (active ? '#eaf8ff' : 'white')};
      border-radius: ${({ active }) => (active ? '50% 0% 0 50%' : '0')};
    }
  }
  & span {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
    & svg {
      position: relative;
      top: 0;
      width: 27px;
      height: auto;
      opacity: ${({ active }) => (!active ? 1 : 0)};
      color: ${({ theme }) => theme.palette.blue.dark};
      transition: all 500ms ease;
      transition-delay: ${({ active }) => (!active ? '300ms' : '0s')};
    }
    &::after {
      content: '${({ name }) => name}';
      position: relative;
      top: -10px;
      visibility: ${({ active }) => (active ? 'hidden' : 'visible')};
      font-weight: 500;
      font-size: 75%;
      text-transform: uppercase;
      color: ${({ theme }) => darken(theme.palette.blue.dark, 0.2)};
      z-index: 1;
      transition: all 600ms ease;
      transition-delay: ${({ active }) => (!active ? '300ms' : '0s')};
      opacity: 0;
    }
    &:hover {
      svg {
        color: ${({ theme }) => darken(theme.palette.blue.dark, 0.2)};
        top: -10px;
      }
      &::after {
        opacity: ${({ active }) => (active ? '0' : '1')};
      }
    }
  }
`;

const MenuGrid = styled(Grid)`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    left: ${({ open }) => (open ? '0px' : '-85px')};
    position: fixed;
  }
  left: 0px;
  width: 85px !important;
  height: 100vh;
  min-height: 850px;
  background-color: white;
  position: fixed;
  transition: all 500ms ease;
  z-index: 5;
`;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: #eaf8ff;
  opacity: 0;
  visibility: hidden;
  z-index: 4;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  transition: all 500ms ease;
  svg {
    left: 0;
    position: absolute;
    height: auto;
    color: ${({ theme }) => theme.palette.blue.dark};
    transition: inherit;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    opacity: ${({ open }) => (open ? '85%' : 0)};
    visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
    svg {
      left: ${({ open }) => (open ? '100px' : '10px')};
      top: ${({ open }) => (open ? '20px' : '10px')};
      width: ${({ open }) => (open ? '36px' : '27px')};
    }
  }
`;

const LogoGrid = styled(Grid)`
  margin: 16px 0 50px 0 !important;
  & img {
    width: 55px;
    height: 55px;
  }
`;

function Navigator({ open, showMenu, pageData, dispatch }) {
  const history = useNavigate();
  let location = useLocation();
  const [selected, setSelected] = useState(0);

  const handleRoute = async (sel, route) => {
    await setSelected(sel);
    if (route) await history(route);
    else await logout({ pageData, dispatch, history });
    setTimeout(() => showMenu(false), 800);
  };

  useEffect(() => {
    const path = location.pathname.split('/admin/')[1];
    const bc = path.split('/').reduce((arr, val) => (!val.match(/\d+/g) ? [...arr, val] : arr), []);
    setSelected(bc[0]);
  }, [history]);

  return (
    <>
      <MenuGrid container direction="column" alignItems="center" open={open}>
        <LogoGrid item>
          <img alt="Logo" src={logoImg} />
        </LogoGrid>
        {menu.map(({ key, name, icon, textWidth, route }, i, ar) => {
          const active = selected === key;
          return (
            <MenuItem
              item
              textwidth={textWidth}
              key={i}
              active={active ? 1 : 0}
              style={i < ar.length - 1 ? { top: `-${50 * i}px` } : {}}
              name={name}
            >
              <div />
              <p>{name}</p>
              <span onClick={() => handleRoute(i, route)}>{icon}</span>
            </MenuItem>
          );
        })}
      </MenuGrid>
      <Backdrop open={open} onClick={() => showMenu(false)}>
        <CloseIcon />
      </Backdrop>
    </>
  );
}

Navigator.propTypes = {
  pageData: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  open: PropTypes.bool,
  showMenu: PropTypes.func,
};

export default Navigator;
