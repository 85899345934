import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';

import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
  card: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    padding: '20px',
    marginBottom: '20px',
    minWidth: '200px',
    maxWidth: '1200px',
    overflow: 'hidden',
    height: '100%',
    borderRadius: '20px',
    backgroundColor: 'white',
    boxShadow: '0px 3px 15px rgba(0, 0, 0, 0.1)',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  cardBox: {
    marginTop: '20px',
    position: 'relative',
    flexGrow: 1,
    alignItems: 'baseline',
    '& :not(:last-child)': {
      marginRight: '10px',
    },
  },
  headerRight: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    right: 20,
    [theme.breakpoints.down('xs')]: {
      position: 'unset',
      width: '100%',
      justifyContent: 'space-around',
    },
  },
  avatar: {
    width: '50px',
    height: '50px',
    backgroundColor: '#f0faff',
    '& svg': {
      color: theme.palette.info.light,
    },
  },
  title: {
    textTransform: 'capitalize',
  },
  chart: {
    height: '100%',
    width: '100%',
    minHeight: '100px',
    minWidth: '230px',
  },
});

const Card = ({ title, avatar, headerRight, classes: st, children, direction, spacing }) => (
  <Grid container alignItems="center" className={st.card}>
    <Grid container alignItems="center" spacing={2}>
      <Grid item>
        <Avatar className={st.avatar}>{avatar}</Avatar>
      </Grid>
      <Grid item>
        <Typography align="left" variant="h5" color="textSecondary" className={st.title}>
          {title}
        </Typography>
      </Grid>
      <Grid item className={st.headerRight}>
        {headerRight}
      </Grid>
    </Grid>
    <Grid container className={st.cardBox} direction={direction} spacing={spacing}>
      {children}
    </Grid>
  </Grid>
);

Card.propTypes = {
  title: PropTypes.string.isRequired,
  direction: PropTypes.string,
  spacing: PropTypes.number,
  avatar: PropTypes.object.isRequired,
  headerRight: PropTypes.node,
  classes: PropTypes.object.isRequired,
  children: PropTypes.node,
};

Card.defaultProps = {
  direction: 'column',
  spacing: 0,
};

export default withStyles(styles)(Card);
