import React, { useState, useEffect, useRef } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip';

import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { delApi } from '@services/api';

const styles = theme => ({
  button: {
    opacity: 0.8,
    borderRadius: 50,
    width: 130,
    height: 35,
    fontSize: '1rem',
    backgroundColor: theme.palette.secondary.light,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
    '& svg': {
      marginRight: 20,
    },
    [theme.breakpoints.down('xs')]: {
      width: '25vw',
      height: '10vw',
      fontSize: 'calc(10px + 1vw)',
      whiteSpace: 'nowrap',
      minHeight: 35,
      maxHeight: 35,
      minWidth: 90,
      maxWidth: 130,
      '& svg': {
        marginRight: 5,
      },
    },
  },
});

const DeleteButton = ({ active, user, thing, notification, classes: st }) => {
  const history = useNavigate();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const reset = async () =>
    delApi(`/things/${thing}`).then(({ status }) => {
      if (status === 200) {
        notification('success', 'Sucess');
        history('/admin/things');
      } else notification('error', 'An error occurred.');
      handleClose();
    });

  return (
    <>
      <Button variant="contained" className={st.button} onClick={handleClickOpen}>
        <DeleteOutlineIcon />
        Delete
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This device will be deleted permanently.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            No
          </Button>
          <Button onClick={() => reset()} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

DeleteButton.propTypes = {
  active: PropTypes.bool,
  thing: PropTypes.string.isRequired,
  user: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  notification: PropTypes.func.isRequired,
};

DeleteButton.defaultProps = {
  active: true,
};
export default withStyles(styles)(DeleteButton);
