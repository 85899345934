import { useIndexedDB } from 'react-indexed-db';
// import _ from 'lodash';

export const addArrayIDB = async (array, db) => {
  const { add, getAll, clear } = useIndexedDB(db);
  // let result = await getAll();
  // result = _.xorBy(array, result, 'key');
  await clear().then(() => array.map(item => add(item)));
};

export const addIDB = async (data, db) => {
  const { add } = useIndexedDB(db);
  add(data);
};

export const getAllIDB = async db => {
  const { getAll } = useIndexedDB(db);
  return getAll();
};

export const delIDB = async (id, db) => {
  const { deleteRecord } = useIndexedDB(db);
  return deleteRecord(id);
};
