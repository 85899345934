import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import 'babel-polyfill';
import moment from 'moment';
import _ from 'lodash';

import { fetchApiHooks, postApi, fetchApi } from '@services/api';
import { handleUpdateAuth, handleUpdateNotifications } from '@stores/page/page.actions';

import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import LocalGasStationIcon from '@material-ui/icons/LocalGasStation';
import WifiOffIcon from '@material-ui/icons/WifiOff';
import WifiIcon from '@material-ui/icons/Wifi';
import CircularProgress from '@material-ui/core/CircularProgress';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import { Skeleton } from '@material-ui/lab';

import flashIcon from '@resources/icons/flash.svg';
import heaterIcon from '@resources/icons/heater.svg';
import loadingIcon from '@resources/loading.svg';

import SolidGauge from '@components/charts/SolidGauge.component';
import AddDeviceBtn from '../components/AddDeviceButton/AddDeviceButton.component';

import firebase from 'firebase/compat/app';
import 'firebase/compat/messaging';
import { fbConfig } from '../../firebase.config';

const styles = theme => ({
  mainContainer: {
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
  paper: {
    margin: 'auto',
    overflow: 'hidden',
  },
  setup: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    zIndex: 2,
    '& p': {
      marginTop: '1rem',
      color: theme.palette.blue.dark,
      zIndex: 2,
    },
  },
  backDrop: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    opacity: 0.9,
    backgroundColor: 'white',
  },
  energyRd: {
    padding: '30px 10px',
    minWidth: '200px',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    fontSize: '36px',
    fontWeight: 500,
    color: theme.palette.grey.dark,
    border: '1px solid',
    borderColor: theme.palette.grey.main,
    borderRadius: '10px',
    '& svg': {
      width: '35px',
      height: '35px',
      color: theme.palette.info.main,
    },
    '& p': {
      opacity: 0.5,
      fontWeight: 500,
      fontSize: '18px',
      marginLeft: '10px',
    },
  },
  avatar: {
    width: '50px',
    height: '50px',
    backgroundColor: '#f0faff',
    '& svg': {
      color: theme.palette.info.light,
    },
  },
  powerBtOn: {
    width: '100px',
    height: '100px',
    backgroundColor: '#f0faff',
    borderRadius: '50%',
    zIndex: 1,
    '& svg': {
      width: '60px',
      height: '60px',
      color: theme.palette.info.light,
    },
  },
  powerBtOff: {
    width: '100px',
    height: '100px',
    backgroundColor: theme.palette.grey.light,
    borderRadius: '50%',
    zIndex: 1,
    '& svg': {
      width: '60px',
      height: '60px',
      color: theme.palette.grey.main,
    },
  },
  '@keyframes spin': {
    to: { transform: 'rotate(360deg)' },
  },
  loading: {
    position: 'absolute',
    width: '30px',
    height: '30px',
    animation: '$spin 1.5s linear infinite',
    zIndex: 2,
  },
  card: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    minWidth: '300px',
    maxWidth: '300px',
    minHeight: '300px',
    maxHeight: '300px',
    overflow: 'hidden',
    margin: '8px',
    height: '100%',
    borderRadius: '30px',
    backgroundColor: 'white',
    boxShadow: '0px 3px 15px rgba(0, 0, 0, 0.1)',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  cardBox: {
    padding: '10px',
    position: 'relative',
    flexGrow: 1,
    alignItems: 'baseline',
    justifyContent: 'center',
  },
  cardDescription: {
    borderRadius: '0 27px 27px 27px',
    backgroundColor: '#F0F0F0',
    padding: '15px',
    minHeight: '110px',
    width: '100%',
  },
  cardStatus: {
    position: 'absolute',
    right: 10,
    '& svg': {
      color: theme.palette.info.main,
    },
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  addUser: {
    marginRight: theme.spacing(1),
  },
  contentWrapper: {
    margin: '40px 0px',
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: 200,
    },
    '& hr': {
      width: '90%',
      opacity: 0.2,
    },
  },
  skeleton: {
    width: 300,
    height: 300,
    transform: 'unset',
  },
  pageLoading: {
    position: 'absolute',
    top: '10%',
    left: '50%',
  },
});

const OilWidget = ({ data, st }) => {
  const history = useNavigate();
  const [thing, setThing] = useState({});
  const [level, setLevel] = useState(0);

  useEffect(() => {
    if (!_.isEmpty(data)) {
      const lvl = (data?.reading / data?.configs?.height) * 100 - 100;
      setLevel(Math.floor(lvl * -1));
      setThing(data);
    }
  }, [data]);

  useEffect(() => {
    if (!_.isEmpty(thing) && !thing.setup)
      setTimeout(() => {
        fetchApi(`/things/${thing.uuid}`).then(({ status, data: d }) => status === 200 && setThing(d));
      }, 4000);
    if (!_.isEmpty(thing) && thing.update === 'UPDATE')
      setTimeout(() => {
        fetchApi(`/things/${thing.uuid}`).then(
          ({ status, data: d }) => status === 200 && thing.update === 'UPDATE' && setThing(d)
        );
      }, 4000);
  }, [thing]);

  return (
    <Grid
      item
      xs={12}
      sm={4}
      md={3}
      lg={2}
      key={thing.uuid}
      className={st.card}
      onClick={() => history(`/admin/things/oil/${thing.uuid}`)}
    >
      {(!thing.setup || thing.update === 'UPDATE') && (
        <div className={st.setup}>
          <div className={st.backDrop} />
          <CircularProgress />
          <Typography variant="body1" color="textSecondary">
            {!thing.setup ? 'Installing...' : 'Updating...'}
          </Typography>
        </div>
      )}
      <Grid container alignItems="center" className={st.cardBox}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <Avatar className={st.avatar}>
              <LocalGasStationIcon />
            </Avatar>
          </Grid>
          <Grid item>
            <Typography align="left" variant="h5" color="textSecondary">
              {thing.name}
            </Typography>
          </Grid>
          <Grid item className={st.cardStatus}>
            <Tooltip arrow title={thing?.online ? 'Connected' : 'Disconnected'}>
              {thing?.online ? <WifiIcon /> : <WifiOffIcon />}
            </Tooltip>
          </Grid>
        </Grid>

        <Grid item>
          <SolidGauge level={level} />
        </Grid>
      </Grid>
      <Grid item className={st.cardDescription}>
        <Typography align="left" color="textSecondary">
          <strong>Reading Time: </strong>
          {`${thing.interval / 60 / 60 / 1000}h`}
        </Typography>
        <Typography align="left" color="textSecondary">
          <strong>Current Reading: </strong>
          {`${thing?.reading ? `${thing?.reading} cm` : 'Waiting...'}`}
        </Typography>
        <Typography align="left" color="textSecondary">
          <strong>Last Updated: </strong>
          {moment(thing.updatedAt).fromNow()}
        </Typography>
      </Grid>
    </Grid>
  );
};
OilWidget.propTypes = {
  st: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

const HeaterWidget = ({ data, st, notification }) => {
  const history = useNavigate();
  const [loading, setLoading] = useState(false);
  const [thing, setThing] = useState({});

  useEffect(() => !_.isEmpty(data) && setThing(data), [data]);

  useEffect(() => {
    if (!_.isEmpty(thing) && !thing.setup)
      setTimeout(() => {
        fetchApi(`/things/${thing.uuid}`).then(({ status, data: d }) => status === 200 && setThing(d));
      }, 4000);
    if (!_.isEmpty(thing) && thing.update === 'UPDATE')
      setTimeout(() => {
        fetchApi(`/things/${thing.uuid}`).then(
          ({ status, data: d }) => status === 200 && thing.update === 'UPDATE' && setThing(d)
        );
      }, 4000);
  }, [thing]);

  const changeState = async () => {
    await setLoading(true);
    await postApi(`/things/${thing.uuid}/state`, { reading: _.toNumber(!thing.reading ? 1 : 0) }).then(
      async ({ status: stat }) => {
        if (stat === 200) {
          setTimeout(
            () =>
              fetchApi(`things/${thing.uuid}`)
                .then(({ status, data: d }) => {
                  if (status === 200 && d.reading !== thing.reading) setThing(d);
                  else notification('error', 'An error occurred.');
                  setLoading(false);
                })
                .catch(error => {
                  notification('error', 'An error occurred.');
                  setLoading(false);
                }),
            1500
          );
        } else {
          notification('error', 'An error occurred.');
          setLoading(false);
        }
      }
    );
  };

  return (
    <Grid item xs={12} sm={4} md={3} lg={2} key={thing.uuid} className={st.card}>
      {(!thing.setup || thing.update === 'UPDATE') && (
        <div className={st.setup}>
          <div className={st.backDrop} />
          <CircularProgress />
          <Typography variant="body1" color="textSecondary">
            {!thing.setup ? 'Installing...' : 'Updating...'}
          </Typography>
        </div>
      )}
      <Grid container alignItems="center" className={st.cardBox}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <Avatar className={st.avatar}>
              <img alt="Heater" src={heaterIcon} />
            </Avatar>
          </Grid>
          <Grid item onClick={() => history(`/admin/things/heater/${thing.uuid}`)}>
            <Typography align="left" variant="h5" color="textSecondary">
              {thing.name}
            </Typography>
          </Grid>
          <Grid item className={st.cardStatus}>
            <Tooltip arrow title={thing?.online ? 'Connected' : 'Disconnected'}>
              {thing?.online ? <WifiIcon /> : <WifiOffIcon />}
            </Tooltip>
          </Grid>
        </Grid>

        <Grid
          container
          alignItems="center"
          justifyContent="center"
          className={thing.reading ? st.powerBtOn : st.powerBtOff}
          style={loading ? { opacity: '0.4' } : {}}
          onClick={() => thing?.online && changeState()}
        >
          {loading && <img alt="loading" className={st.loading} src={loadingIcon} />}
          <PowerSettingsNewIcon />
        </Grid>
      </Grid>
      <Grid item className={st.cardDescription}>
        <Typography align="left" color="textSecondary">
          <strong>Reading Time: </strong>
          {`${thing.interval / 60 / 60 / 1000}h`}
        </Typography>
        <Typography align="left" color="textSecondary">
          <strong>Current State: </strong>
          {`${thing.reading ? 'ON' : 'OFF'}`}
        </Typography>
        <Typography align="left" color="textSecondary">
          <strong>Last Updated: </strong>
          {moment(thing.updatedAt).fromNow()}
        </Typography>
      </Grid>
    </Grid>
  );
};
HeaterWidget.propTypes = {
  notification: PropTypes.func.isRequired,
  st: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

const EnergyWidget = ({ data, st }) => {
  const history = useNavigate();
  const [thing, setThing] = useState({});

  useEffect(() => !_.isEmpty(data) && setThing(data), [data]);

  useEffect(() => {
    if (!_.isEmpty(thing) && !thing.setup)
      setTimeout(() => {
        fetchApi(`/things/${thing.uuid}`).then(({ status, data: d }) => status === 200 && setThing(d));
      }, 4000);
    if (!_.isEmpty(thing) && thing.update === 'UPDATE')
      setTimeout(() => {
        fetchApi(`/things/${thing.uuid}`).then(
          ({ status, data: d }) => status === 200 && thing.update === 'UPDATE' && setThing(d)
        );
      }, 4000);
  }, [thing]);

  return (
    <Grid
      item
      xs={12}
      sm={4}
      md={3}
      lg={2}
      key={thing.uuid}
      className={st.card}
      onClick={() => history(`/admin/things/energy/${thing.uuid}`)}
    >
      {(!thing.setup || thing.update === 'UPDATE') && (
        <div className={st.setup}>
          <div className={st.backDrop} />
          <CircularProgress />
          <Typography variant="body1" color="textSecondary">
            {!thing.setup ? 'Installing...' : 'Updating...'}
          </Typography>
        </div>
      )}
      <Grid container alignItems="center" className={st.cardBox}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <Avatar className={st.avatar}>
              <img alt="Enegry" src={flashIcon} />
            </Avatar>
          </Grid>
          <Grid item>
            <Typography align="left" variant="h5" color="textSecondary">
              {thing.name}
            </Typography>
          </Grid>
          <Grid item className={st.cardStatus}>
            <Tooltip arrow title={thing?.online ? 'Connected' : 'Disconnected'}>
              {thing?.online ? <WifiIcon /> : <WifiOffIcon />}
            </Tooltip>
          </Grid>
        </Grid>

        <Grid container justifyContent="center" alignItems="center">
          <div className={st.energyRd}>
            <FlashOnIcon />
            {_.round(thing?.reading, 2) || 0}
            <Typography>KW/H</Typography>
          </div>
        </Grid>
      </Grid>

      <Grid item className={st.cardDescription}>
        <Typography align="left" color="textSecondary">
          <strong>Reading Time: </strong>
          {`${thing.interval / 60 / 60 / 1000}h`}
        </Typography>
        <Typography align="left" color="textSecondary">
          <strong>Current Reading: </strong>
          {`${thing?.reading ? `${thing?.reading} KW/H` : 'Waiting...'}`}
        </Typography>
        <Typography align="left" color="textSecondary">
          <strong>Last Updated: </strong>
          {moment(thing.updatedAt).fromNow()}
        </Typography>
      </Grid>
    </Grid>
  );
};
EnergyWidget.propTypes = {
  st: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

const Things = ({ notification, classes: st, dispatch, pageData }) => {
  const [things, setThings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fbRefresh, setFbRefresh] = useState(false);

  useEffect(() => {
    // FIREBASE NOTIFICATIONS
    const fetch = async () => {
      if ('serviceWorker' in navigator && 'Notification' in window && process.env.NODE_ENV === 'production') {
        if (firebase.apps.length === 0) firebase.initializeApp(fbConfig.config);
        const messaging = firebase.messaging();
        if (!pageData.auth?.fb) {
          Notification.requestPermission().then(perm => {
            if (perm === 'granted')
              messaging
                .getToken({ vapidKey: fbConfig.publicKey })
                .then(async tk => {
                  dispatch(handleUpdateAuth({ ...pageData.auth, fb: tk }));
                  await postApi(`/auth/firebase`, { fbToken: tk });
                })
                .catch(() => setTimeout(() => setFbRefresh(!fbRefresh), 1000));
          });
        } else await postApi(`/auth/firebase`, { fbToken: pageData.auth.fb });
        messaging.onMessage(payload => notification('info', payload.notification.body));
      }
    };
    fetch();
  }, [notification, fbRefresh, pageData, dispatch]);

  fetchApiHooks(
    'things',
    {},
    res => {
      setThings(res.data);
      const ntfs = res.data.reduce((arr, th) => {
        if (th.update === 'NEW') arr.push({ uuid: th.uuid, type: th.type, msgType: 'UPDATE' });
        return arr;
      }, []);
      if (!_.isEmpty(ntfs)) dispatch(handleUpdateNotifications(ntfs));
    },
    [pageData.refresh],
    l => setLoading(l)
  );

  if (loading) return <CircularProgress className={st.pageLoading} />;
  if (_.isEmpty(things)) return <AddDeviceBtn userUuid={pageData.auth?.uuid} firstTime />;
  return (
    <Grid
      container
      direction="row"
      className={st.mainContainer}
      justifyContent={things.length > 1 ? 'center' : 'flex-start'}
    >
      {things.map((t, i) => {
        let Widget;
        switch (t.type) {
          case 'OIL':
            Widget = OilWidget;
            break;
          case 'ENERGY':
            Widget = EnergyWidget;
            break;
          case 'HEATER':
            Widget = HeaterWidget;
            break;
          default:
            Widget = OilWidget;
        }
        return <Widget data={t} key={i} st={st} notification={notification} />;
      })}
    </Grid>
  );
};

Things.propTypes = {
  pageData: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  notification: PropTypes.func.isRequired,
};

const mapStateToProps = ({ pageData }) => ({ pageData });
export default connect(mapStateToProps)(withStyles(styles)(Things));
